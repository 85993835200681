import {useState,useEffect,useRef} from 'react'
import {Lucid,Blockfrost,Data} from "lucid-cardano"
import {Lending, Network, blockfrostKey} from './shared.js'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Wallet from './components/wallet/Wallet.jsx';
import HistoryFights from './components/HistoryFights';
import HistoryLocalSales from './components/HistoryLocalSales.jsx';
import Index from './components/Index';
import Faq from './components/Faq';
import Punk from './components/Punk.jsx';
import Buy from './components/buy/Buy.jsx';
import Fight from './components/fight/Fight.jsx';
import AllPunks from './components/AllPunks/AllPunks.jsx';
import Lendings from './components/lendings/Lendings.jsx';
import HistoryAll from './components/HistoryAll.jsx';
import HistoryTop from './components/HistoryTop.jsx';
import GridOffers from './components/wallet/GridOffers.jsx';
import Offers from './components/offers/OffersView.jsx';
import Commands from './components/Commands.jsx';
import Team from './components/Team.jsx';
import Roadmap from './components/Roadmap.jsx';
import Updates from './components/Updates.jsx';
import HistoryLoans from './components/HistoryLoans.jsx';




/* global BigInt */

function App() {

  const [listedItems, setListedItems] = useState([]);
  const [listedOrder, setListedOrder] = useState('recently');
  const [myPunks, setMyPunks] = useState([]);
  const [myPunksOrder, setMyPunksOrder] = useState('rank');
  const [walletItems, setWalletItems] = useState([]);
  const [walletItemsOrder, setWalletItemsOrder] = useState('rank');
  const [allPunks, setAllPunks] = useState({total:0,assets:[]});
  const [lendingItems, setLendingItems] = useState([]);
  const [allSales, setAllSales] = useState({page:0,assets:[],total:0});

  const [address, setAddress] = useState(null);
  // const [lucid, setLucid] = useState(null);
  const lucid = useRef();


  const connectNami = async () => {
    console.log(window.cardano);
    if (window.cardano!=null && window.cardano.nami!=null){
      // const lucid = await Lucid.new(null,Network);
      lucid.current = await Lucid.new(new Blockfrost("https://cardano-"+Network.toLowerCase()+".blockfrost.io/api/v0", blockfrostKey),Network);
      const api = await window.cardano.nami.enable();
      lucid.current.selectWallet(api);
      if (lucid.current.wallet!=null){
        window.$.cookie("wallet", "nami", { expires : 999 });
        const addr = await lucid.current.wallet.address();
        setAddress(addr);
      }
    }else{
      window.$('#alert-modal-body').html("No cardano Nami wallet detected.");
      window.$('#alert-modal').modal('show');
    }
  };

  const connectVespr = async () => {
    if (window.cardano!=null && window.cardano.vespr!=null){
      // const lucid = await Lucid.new(null,Network);
      lucid.current = await Lucid.new(new Blockfrost("https://cardano-"+Network.toLowerCase()+".blockfrost.io/api/v0", blockfrostKey),Network);
      const api = await window.cardano.vespr.enable();
      lucid.current.selectWallet(api);
      if (lucid.current.wallet!=null){
        window.$.cookie("wallet", "vespr", { expires : 999 });
        const addr = await lucid.current.wallet.address();
        setAddress(addr);
      }
    }else{
      window.$('#alert-modal-body').html("No cardano Vespr wallet detected.");
      window.$('#alert-modal').modal('show');
    }
  };

  const connectEternl = async () => {
    if (window.cardano!=null && window.cardano.eternl!=null){
      // const lucid = await Lucid.new(null,Network);
      lucid.current = await Lucid.new(new Blockfrost("https://cardano-"+Network.toLowerCase()+".blockfrost.io/api/v0", blockfrostKey),Network);
      try{
        const api = await window.cardano.eternl.enable();
        lucid.current.selectWallet(api);
        if (lucid.current.wallet!=null){
          window.$.cookie("wallet", "eternl", { expires : 999 });
          const addr = await lucid.current.wallet.address();
          setAddress(addr);
        }
      }catch(e){
        window.$('#alert-modal-body').html(e);
        window.$('#alert-modal').modal('show');
      }
    }else{
      window.$('#alert-modal-body').html("No cardano Eternl wallet detected.");
      window.$('#alert-modal').modal('show');
    }
  };

  const connectEternlMobile = async () => {
    console.log(window.initCardanoDAppConnectorBridge);
    // Calling this function is mandatory.
    window.initCardanoDAppConnectorBridge(async (walletApi) => {

      if (window.cardano!=null && window.cardano.eternl!=null){
        // const lucid = await Lucid.new(null,Network);
        lucid.current = await Lucid.new(new Blockfrost("https://cardano-"+Network.toLowerCase()+".blockfrost.io/api/v0", blockfrostKey),Network);
        try{
          const fullApi = await walletApi.enable() // walletApi is window.cardano.eternl
          lucid.current.selectWallet(fullApi);
          if (lucid.current.wallet!=null){
            window.walletFees = window.cardano.eternl.experimental.feeAddress;
            window.$.cookie("wallet", "eternlMobile", { expires : 999 });
            const addr = await lucid.current.wallet.address();
            setAddress(addr);
          }
        }catch(e){
          window.$('#alert-modal-body').html(e);
          window.$('#alert-modal').modal('show');
        }
      }else{
        window.$('#alert-modal-body').html("No cardano Eternl wallet detected.");
        window.$('#alert-modal').modal('show');
      }
    })
  };

  async function walletChangueMonitor(){
    try{
      const addr = await lucid.current.wallet.address();
      setAddress(addr);
    }catch(e){console.log(e)}
  }

  useEffect(async () => {
    if(window.$.cookie('wallet')==="nami")
      connectNami();
    else if(window.$.cookie('wallet')==="eternl")
      connectEternl();
    else if(window.$.cookie('wallet')==="vespr")
      connectVespr();
    else if(window.$.cookie('wallet')==="eternlMobile")
      connectEternlMobile();

    const interval = setInterval(walletChangueMonitor, 5000);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <BrowserRouter>
        <Header address={address} connectNami={connectNami}  connectVespr={connectVespr} connectEternl={connectEternl} connectEternlMobile={connectEternlMobile} emptyAllPunks={() => setAllPunks({total:0,assets:[]})}/>
        <Routes>
          {/* <Route path='/fight' element={<Fight address={address} lucid={lucid.current}/>}/> */}
          <Route path='/buy' element={<Buy address={address} lucid={lucid.current} items={listedItems} setItems={setListedItems}  order={listedOrder} setOrder={setListedOrder}/>}/>
          <Route path='/offers' element={<Offers address={address} lucid={lucid.current} items={listedItems} setItems={setListedItems}  order={listedOrder} setOrder={setListedOrder}></Offers>}/>
          <Route path='/myPunks' element={<Wallet address={address} lucid={lucid.current} items={myPunks} setItems={setMyPunks} order={myPunksOrder} setOrder={setMyPunksOrder}/>}/>
          {/* <Route path='/wallet' element={<Wallet address={address} lucid={lucid.current} items={walletItems} setItems={setWalletItems} order={walletItemsOrder} setOrder={setWalletItemsOrder}/>}/> */}
          <Route path='/listFights' element={<HistoryFights address={address} />}/>
          <Route path='/localSales' element={<HistoryLocalSales address={address} emptyWallet={() => setWalletItems([])}/>}/>
          <Route path='/listLoans' element={<HistoryLoans address={address} emptyWallet={() => setWalletItems([])}/>}/>
          <Route path='/listAll' element={<HistoryAll data={allSales} setData={setAllSales} address={address} emptyWallet={() => setWalletItems([])} showSearch={true}/>}/>
          <Route path='/sales' element={<HistoryAll data={allSales} setData={setAllSales} address={address} emptyWallet={() => setWalletItems([])} showSearch={true}/>}/>
          <Route path='/listTop' element={<HistoryTop address={address} emptyWallet={() => setWalletItems([])}/>}/>
          <Route path='/topSales' element={<HistoryTop address={address} emptyWallet={() => setWalletItems([])}/>}/>
          <Route path='/punk' element={<Punk  address={address} lucid={lucid.current} emptyWallet={() => setWalletItems([])}/>}/>
          <Route path="/faq" element={<Faq />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/road" element={<Roadmap />} />
          <Route path="/team" element={<Team />} />
          <Route path="/cmd" element={<Commands  lucid={lucid.current} address={address} />} />
          <Route path='/adapunks' element={<AllPunks address={address} lucid={lucid.current} items={allPunks} setItems={setAllPunks}/>}/>
          <Route path='/lending/*' element={<Lendings address={address} lucid={lucid.current} items={lendingItems} setItems={setLendingItems}  order={listedOrder} setOrder={setListedOrder}/>}/>
          <Route path='/mywallet/*' element={<Wallet address={address} lucid={lucid.current} items={myPunks} setItems={setMyPunks} order={myPunksOrder} setOrder={setMyPunksOrder}/>}/>
          <Route path='/wallet/*' element={<Wallet address={address} lucid={lucid.current} items={walletItems} setItems={setWalletItems} order={walletItemsOrder} setOrder={setWalletItemsOrder}/>}/>
          <Route path="*" element={<Index />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
