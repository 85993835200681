import {useState,useEffect} from 'react'
import Card from '../shared/Card';
import {cancelSale} from '../shared/Functions.jsx';
import {getAddressDetails} from "lucid-cardano"
import { FiRefreshCcw } from "react-icons/fi";

function GridOnSale({address,lucid,showButtons,setTxId,setSubmittedModal}) {

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState('rank');
  const [reload, setReload] = useState(0);
  const [items, setItems] = useState([]);
  const pkh = (address!=null?getAddressDetails(address).paymentCredential.hash:null);

  async function cancel(id){
    const txid= await cancelSale(id,pkh,lucid,items,setItems);
    if (txid!=null){
      setTxId(txid);
      setSubmittedModal(true);
    }
  }

  function sortAndSet(by) {
    setOrder(by);
    setItems(sort(by,items));
  }

  function sort(by) {
    const newItems = [...items];
    if (by==="recently")
    newItems.sort(function(a, b) {
      if (a.index < b.index) return 1;
      if (a.index > b.index) return -1;
      return 0;
    });
    if (by==="price")
    newItems.sort(function(a, b) {
      if (a.amount < b.amount) return -1;
      if (a.amount > b.amount) return 1;
      return 0;
    });
    if (by==="rank")
    newItems.sort(function(a, b) {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return 0;
    });

    return newItems;
  }

  useEffect(() => {
    if (address!=null){
      setLoading(true);
      window.$.ajax({
        url: '/getSCLists',
        type: 'GET',
        dataType: "json",
        data: {addr: address},
        success: function (data) {
          setItems(data);
          setLoading(false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
    }else{
      setLoading(false);
    }
  }, [address,reload]);

  return (
    <>
      <div className='container'>
        <div className='bg-body shadow-sm mt-3 p-3 rounded-3'>
          <div className='row'>
            <div className='col-12 col-md-3 col-lg-2'>
              <select className='form-select form-select-lg' value={order} onChange={e => {sortAndSet(e.target.value)}} aria-label='.form-select-lg example'>
                <option value='rank'>By rank</option>
                <option value='name'>By name</option>
              </select>
            </div>
            <div className='col-6 col-md-1 offset-md-5  offset-lg-7  text-center pt-2' >
              {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
              {!loading && <FiRefreshCcw style={{cursor:'pointer',width: '25px',height: '25px'}} onClick={() => setReload((val) => {return val+1})}/>}
            </div>
            <div className='col-6 col-md-3 col-lg-2 text-center pt-2' >Punks on sale: <label>{items.length} </label></div>
          </div>
        </div>
      </div>
      <div className='row row-cols-2  row-cols-sm-3 row-cols-md-4 row-cols-xl-5 g-4 mt-2'>
        {
          items.map((item) =>
            <Card
              key={item.id}
              id={item.id} 
              name={item.name}
              rank={item.rank}
              polId={item.policyId}

            >
              {showButtons && 
                <>
                  {item.datum.owner===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => cancel(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Delist':'Pending Tx...'}</button>}
                </>
              }
            </Card>
          ) 
        }
      </div>
    </>

  );
}

export default GridOnSale;