import {useState} from 'react';
import { FiRefreshCcw } from "react-icons/fi";
import {useLocation,Link} from 'react-router-dom';


function Filters({totalAssets,order,sort,reload,loading,lend}) {

  const { hash, pathname, search } = useLocation();


  return (

    <div className='container'>
      <div className='bg-body shadow-sm mt-3 p-3 rounded-3'>
        <div className='row'>
            <div className='col-7 col-md-3 col-lg-2'>
            <select className='form-select form-select-lg' value={order} onChange={e => {sort(e.target.value)}} aria-label='.form-select-lg example'>
              <option value='recently'>Recently listed</option>
              <option value='price'>By price</option>
              <option value='rank'>By rank</option>
            </select>
          </div>
          <div className='col-5 col-md-3 col-lg-2'>
            {pathname.endsWith('borrows') && <Link to='/mywallet/punks' className="btn btn-primary h-100 w-100">Borrow</Link>}
            {!pathname.endsWith('borrows') && <button className="btn btn-primary h-100 w-100" onClick={() => lend()}>Add offer</button>}
          </div>
          <div className='col-6 col-md-1 offset-md-2  offset-lg-5  text-center pt-2' >
            {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
            {!loading && <FiRefreshCcw style={{cursor:'pointer',width: '25px',height: '25px'}} onClick={() => {reload()}}/>}
          </div>
          <div className='col-6 col-md-3 col-lg-2 text-center pt-2' >Total: <label>{totalAssets}</label></div>
        </div>
      </div>
    </div>
  );
}

export default Filters;