import Card from '../shared/Card';
import {policyId} from '../../shared.js'
import Card2 from '../shared/Card2';


function Grid({items,loadMore,showMore,loading}) {


  return (
    <div className='d-flex flex-wrap mt-3'>
      {
        items.map((item) =>
          <Card2
            key={item.id}
            id={item.id} 
            name={item.name}
            rank={item.rank}
            polId={policyId}
          >
          </Card2>
        ) 
      }
      {showMore && <button disabled={loading} onClick={loadMore} className='btn btn-primary w-100 mt-3'>Load more ...</button>}
    </div>
  );
}

export default Grid;