export async function regFighter(addr,policyId,tokenName,amount,coin,t1,t2,t3,fhash){
    var hash;
    await window.$.ajax({
        url: '/regFighter',
        type: 'GET',
        data: {
          addr: addr,
          policyId: policyId,
          tokenName: tokenName,
          amount: amount,
          coin: coin,
          t1: t1,
          t2: t2,
          t3: t3,
          fhash: fhash
        },
        success: function (data) {
            hash=data;
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
    return hash;
}

export async function regSale(policyId,tokenName,amount,txId,buyer,seller){
  var result;
  await window.$.ajax({
      url: '/regSale',
      type: 'GET',
      data: {
        policyId: policyId,
        tokenName: tokenName,
        amount: amount,
        txId: txId,
        buyer: buyer,
        seller: seller
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function regList(policyId,tokenName,amount,txId,addr,feesAddr,royAddr){
  var result;
  await window.$.ajax({
      url: '/regList',
      type: 'GET',
      data: {
        policyId: policyId,
        tokenName: tokenName,
        amount: amount,
        txId: txId,
        addr: addr,
        feesAddr: feesAddr,
        royAddr: royAddr
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function regBorrow(txId,addr,policyId,tokenName,amount,interest,days,apy,royAddr,feesAddr,status=0){
  var result;
  await window.$.ajax({
      url: '/regBorrow',
      type: 'GET',
      data: {
        txId: txId,
        addr: addr,
        policyId: policyId,
        tokenName: tokenName,
        amount: amount,
        interest: interest,
        days: days,
        apy: apy,
        royAddr: royAddr,
        feesAddr: feesAddr,
        status: status,
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function fundBorrow(txId,newTxId,lenderAddr){
  var result;
  await window.$.ajax({
      url: '/fundBorrow',
      type: 'GET',
      data: {
        txId: txId,
        newTxId: newTxId,
        lenderAddr: lenderAddr,
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function takeBorrowOffer(txId,newTxId,addr,tokenName){
  var result;
  await window.$.ajax({
      url: '/takeBorrowOffer',
      type: 'GET',
      data: {
        txId: txId,
        newTxId: newTxId,
        addr: addr,
        tokenName: tokenName,
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function pay(txId,newTxId,tokenId){
  var result;
  await window.$.ajax({
      url: '/pay',
      type: 'GET',
      data: {
        txId: txId,
        newTxId: newTxId,
        tokenId: tokenId,
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function claim(txId,newTxId,tokenId){
  var result;
  await window.$.ajax({
      url: '/claim',
      type: 'GET',
      data: {
        txId: txId,
        newTxId: newTxId,
        tokenId: tokenId,
      },
      success: function (data) {
          console.log(data);
          result = data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  return result;
}

export async function delist(txId){
    await window.$.ajax({
        url: '/delist',
        type: 'GET',
        data: {
          txId: txId,
        },
        success: function (data) {
            console.log(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
}

export async function unBorrow(txId){
  await window.$.ajax({
      url: '/unBorrow',
      type: 'GET',
      data: {
        txId: txId,
      },
      success: function (data) {
          console.log(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
}


export async function setTxId(hash,txId){
    await window.$.ajax({
        url: '/setTxId',
        type: 'GET',
        data: {
            hash: hash,
            txId: txId
        },
        success: function (data) {
            console.log(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
}

export async function freeFighter(hash){
    await window.$.ajax({
        url: '/freeFighter',
        type: 'GET',
        data: {
            hash: hash,
        },
        success: function (data) {
            console.log(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
}

export async function delFighter(hash){
    await window.$.ajax({
        url: '/delFighter',
        type: 'GET',
        data: {
            hash: hash,
        },
        success: function (data) {
            console.log(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
}

export async function resolve(fhash,chash){
    var result;
    await window.$.ajax({
        url: '/resolve',
        type: 'GET',
        dataType: "json",
        data: {
          fhash: fhash,
          chash: chash,
        },
        success: function (data) {
            result=data;
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })

    return result;
}

export async function getListAddrs(txId){
  var result;
  await window.$.ajax({
      url: '/getListAddrs',
      type: 'GET',
      data: {
        txId: txId,
      },
      success: function (data) {
          result=data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })

  return result;
}

export async function getBorrowAddrs(txId){
  var result;
  await window.$.ajax({
      url: '/getBorrowAddrs',
      type: 'GET',
      data: {
        txId: txId,
      },
      success: function (data) {
          result=data;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })

  return result;
}