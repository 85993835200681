import {Link} from 'react-router-dom';
import {useEffect} from 'react';

function Roadmap() {

  window.scrollTo(0, 0);

  return (
    <div className='mt-3'>

      <h1 className='display-5 fw-bold lh-1 mb-3 text-center'>Roadmap 2023</h1>

      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row-reverse align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>Marketplace</h1>
            <p className="pb-3 mb-0 small lh-sm border-bottom">
              Buy and sell ADAPunks.
            </p>
            <ul>
              <li style={{color:'#198754'}}>Release - 2023/12/07 <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li style={{color:'#198754'}}>History - 2023/31/08  <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li style={{color:'#198754'}}>Offers - 2023/19/09  <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li style={{color:'#198754'}}>Notifications - 2023/20/11  <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li>Editions</li>
              <li>Stats</li>
              <li>Swap system</li>
            </ul>
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/marketplace.png' className='d-block mx-auto img-fluid rounded-3' alt='Bootstrap Themes' width='300' height='300' loading='lazy'/>
          </div>
        </div>
      </div>

      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row-reverse align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>Loans</h1>
            <p className="pb-3 mb-0 small lh-sm border-bottom">
              Borrow your NFT's and get liquidity or lend ADA to earn more ADA.
            </p>
            <ul>
              <li style={{color:'#198754'}}>Release - 2023/19/10 <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li>Borrowers reputation</li>
              <li style={{color:'#198754'}}>Notifications - 2023/20/11  <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li style={{color:'#198754'}}>History - 2023/23/10 <svg xmlns='http://www.w3.org/2000/svg' width={20} viewBox='0 0 8 8'><path fill='#198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg></li>
              <li>Editions</li>
              <li>Stats</li>
              <li>Friend collections</li>
            </ul>          
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/loans.png' className='d-block mx-auto img-fluid rounded-3' alt='Bootstrap Themes' width='300' height='300' loading='lazy'/>
          </div>
        </div>
      </div>

      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row-reverse align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>Staking</h1>
            <p className="pb-3 mb-0 small lh-sm border-bottom">
              Earn ADA by staking your punks. The royalties will be shared to holders in staking.
            </p>
            <ul>
              <li>Staking posts</li>
              <li>Realase: 2024/2025</li>
            </ul>
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/staking.png' className='d-block mx-auto img-fluid rounded-3' alt='Bootstrap Themes' width='300' height='300' loading='lazy'/>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Roadmap;