import {Link} from 'react-router-dom';
import '../css/Footer.css';

function Footer() {


  return (
    <div className="container">
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item"><Link to="/" className="nav-link px-2 text-body-secondary">Home</Link></li>
          <li className="nav-item"><Link to="/road" className="nav-link px-2 text-body-secondary">Roadmap</Link></li>
          <li className="nav-item"><Link to="/faq" className="nav-link px-2 text-body-secondary">FAQ</Link></li>
          {/* <li className="nav-item"><Link to="/team" className="nav-link px-2 text-body-secondary">Team</Link></li> */}
          <li className="nav-item"><a href="https://x.com/adapunksnfts" target="_blank" className="nav-link px-2 text-body-secondary">Twitter</a></li>
          <li className="nav-item"><a href="https://discord.gg/WCEhPZT5aF" target="_blank" className="nav-link px-2 text-body-secondary">Discord</a></li>
          <li className="nav-item"><a href="https://www.jpg.store/collection/adapunksnfts" target="_blank" className="nav-link px-2 text-body-secondary">jpg.store</a></li>
          {/* <li className="nav-item"><Link to="/updates" className="nav-link px-2 text-body-secondary">Updates!</Link></li> */}
        </ul>
        <p className="text-center text-body-secondary">© 2024 adapunks.io</p>
      </footer>
    </div>
  );
}

export default Footer;