import {useState,useEffect} from 'react';
import {SCScript,SCDatum,MarketPlace,MarketPlaceDatum,adap,feesWallet,feesWallet2,royaltiesWallet,Lending,LendingDatum,policyId,tasaFee} from '../../shared.js'
import {getAddressDetails,Data,toText} from "lucid-cardano"
import {doOfferTx} from '../shared/Functions.jsx';
import * as DB from '../../db.js';
import Loading from '../shared/Loading';
import Filters from './Filters';
import Grid from './Grid';
import { Link,useLocation } from 'react-router-dom';
import ModalSell from './ModalSell';
import ModalSubmitted from '../shared/ModalSubmitted.jsx';
import ModalFight from './ModalFight.jsx';
import ModalBorrow from './ModalBorrow.jsx';
import {Route, Routes} from 'react-router-dom';
import GridOnSale from './GridOnsale.jsx';
import GridOffers from './GridOffers.jsx';
import ModalOffer from '../shared/ModalOffer.jsx';
import GridOther from './GridOther.jsx';

/* global BigInt */

function Wallet({address,lucid,items, setItems,order, setOrder}) {

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const [txId, setTxId] = useState('');
  const [selected, setSelected] = useState(null);
  const [sellModal, setSellModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [fightModal, setFightModal] = useState(false);
  const [borrowModal, setBorrowModal] = useState(false);
  //To disable the buy/fight/borrow modal button when wallet singning
  const [modalEnabled, setModalEnabled] = useState(true);
  const [submittedModal, setSubmittedModal] = useState(false);

  

  const { hash, pathname, search } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const wallet = (params.get("addr")!=null?params.get("addr"):address);


  function sortAndSet(by) {
    setOrder(by);
    setItems(sort(by,items));
  }
  function sort(by,items) {
    const newItems = [...items];
    if (by==="rank")
    newItems.sort(function(a, b) {
          if (a.rank < b.rank) return -1;
          if (a.rank > b.rank) return 1;
          return 0;
      });
    if (by==="name")
    newItems.sort(function(a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
      });
    return newItems;
  }


  function fight(id){
    setSelected(items.find(item => item.id===id));
    setFightModal(true);
    setModalEnabled(true);
  }
  function sell(id){
    setSelected(items.find(item => item.id===id));
    setSellModal(true);
    setModalEnabled(true);
  }
  function offer(){
    setOfferModal(true);
    setModalEnabled(true);
  }
  function borrow(id){
    setSelected(items.find(item => item.id===id));
    setBorrowModal(true);
    setModalEnabled(true);
  }

  async function doSell(total){
    const MarketPlaceAddress = await lucid.utils.validatorToAddress(MarketPlace);
    if (total===null || total<10){
      window.$('#alert-modal-body').html("The import must be at least 10 ADA.");
      window.$('#alert-modal').modal('show');
    }else{
      setModalEnabled(false);
      const pkh = getAddressDetails(address).paymentCredential.hash;
      const pkhFees = getAddressDetails(feesWallet).paymentCredential.hash;
      const pkhRoyalties = getAddressDetails(royaltiesWallet).paymentCredential.hash;

      var fees = (total*1000000)*tasaFee;
      if (fees<1000000)
        fees=1000000;
      const amount = (total*1000000) - (fees*2);

      const d = {
        owner: pkh,
        feesWallet: pkhFees,
        royaltiesWallet: pkhRoyalties,
        fees: BigInt(fees),
        royalties: BigInt(fees),
        policy: "",
        tn: "",
        amount: BigInt(amount)
      };
      const datum = Data.to(d,MarketPlaceDatum);
      var tx = await lucid
      .newTx()
      .payToContract(MarketPlaceAddress, { inline: datum }, {[selected.id]: 1n })
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      setModalEnabled(true);
      if (txid!=null){
          DB.regList(selected.policyId,selected.name,amount,txid,address,feesWallet,royaltiesWallet);
          setTxId(txid);
          setSellModal(false);
          setSubmittedModal(true);

          //Disable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===selected.id);
          item.onTx=true;
          setItems(newItems);
      }
    }
  }


  async function doOffer(total){
    const txid= await doOfferTx(total,address,lucid,setModalEnabled);
    if (txid!=null){
      setTxId(txid);
      setOfferModal(false);
      setSubmittedModal(true);
    }
  }

  async function doFight(amount,coin,t1,t2,t3){
    if (amount===null || amount<10)
      alert("The import must be at least 10 ADA/ADAP.");
    else if (coin==='')
      alert("You must pick a coin.");
    else if (t1==='' ||t2==='' || t3==='')
      alert("You must pick your 3 turns.");
    else{
      setModalEnabled(false);
      const SCAddress = await lucid.utils.validatorToAddress(SCScript);

      const pkh = getAddressDetails(address).paymentCredential.hash;
      const hash = await DB.regFighter(address,selected.policyId,selected.name,amount,coin,t1,t2,t3);
  
      const d = {
        owner: pkh,
        tn: selected.assetName,
        hash: hash,
        tipo: BigInt(1),
        amount: BigInt(amount*1000000)
      };
      const datum = Data.to(d,SCDatum);
      var tx = await lucid.newTx();
      if (coin==="ADA")
          tx = await tx.payToContract(SCAddress, { inline: datum }, { lovelace: amount*1000000n, [selected.id]: 1n });
      else
          tx = await tx.payToContract(SCAddress, { inline: datum }, { lovelace: 1750000n, [selected.id]: 1n, [adap]: amount });
      try{
          tx = await tx.complete();
  
          const txid = await signAndSubmitCardanoTx(tx);
          if (txid!=null){
              await DB.setTxId(hash,txid);
              setTxId(txid);
              setFightModal(false);
              setSubmittedModal(true);

              //Disable item
              const newItems = [...items];
              const item = newItems.find(item => item.id===selected.id);
              item.onTx=true;
              setItems(newItems);
          }else{
            DB.delFighter(hash);
          }
      }catch(e){
        DB.delFighter(hash);
        console.log(e);
        window.$('#alert-modal-body').html("You don't have enough funds.");
        window.$('#alert-modal').modal('show');
      }
      setModalEnabled(true);
    }
  }

  async function doBorrow(amount,interest,fees,days,apy){
    if (amount===null || amount<10)
      alert("The amount must be at least 10 ADA/ADAP.");
    else{
      setModalEnabled(false);
      const LendingAddress = await lucid.utils.validatorToAddress(Lending);


      const pkh = getAddressDetails(address).paymentCredential.hash;
      const pkhFees = getAddressDetails(feesWallet).paymentCredential.hash;
      const pkhFees2 = getAddressDetails(feesWallet2).paymentCredential.hash;
      const pkhRoyalties = getAddressDetails(royaltiesWallet).paymentCredential.hash;

      var lovelaceFees = parseInt(fees*1000000);

      const d = {
        owner: pkh,
        lender: '',
        feesWallet: pkhFees,
        royaltiesWallet: (selected.policyId==policyId?pkhRoyalties:pkhFees2),
        fees: BigInt(lovelaceFees),
        royalties: BigInt(lovelaceFees),
        amount: BigInt((parseInt(amount)*1000000)-(lovelaceFees*2)),
        interest: BigInt(Math.ceil(interest*1000000)),
        days: BigInt(days*24*60*60*1000),
        deadline: BigInt(0),
        status: BigInt(0),
        policy: selected.policyId,
        tn: selected.assetName
      };

      const datum = Data.to(d,LendingDatum);
      var tx = await lucid
      .newTx()
      .payToContract(LendingAddress, { inline: datum }, {[selected.id]: 1n })
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      setModalEnabled(true);
      if (txid!=null){
        DB.regBorrow(txid,address,selected.policyId,selected.name,amount,interest,days,apy,(selected.policyId==policyId?royaltiesWallet:feesWallet2),feesWallet);
        setTxId(txid);
        setBorrowModal(false);
        setSubmittedModal(true);

        //Disable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===selected.id);
        item.onTx=true;
        setItems(newItems);
      }
    }
  }

  async function signAndSubmitCardanoTx(tx) {
    try {
        const signedTx = await tx.sign().complete();
        const tid = await signedTx.submit();
        console.log("Cardano tx submitted: " + tid);
        return tid;
    } catch (err) {
      if (err.code===2 && err.info==="Wallet could not send the tx."){
        window.$('#alert-modal-body').html("The asset has been bought/contended by other user. Is not available any more. Or you have a pending transaction.");
        window.$('#alert-modal').modal('show');
        console.log(err);
      }else if (err.code!=2 && err.code!=-3){ //2 User declined to sign the transaction. -3 wallet closed
        window.$('#alert-modal-body').html("You have a pending transaction, please wait a moment an try again.");
        window.$('#alert-modal').modal('show');
        console.log(err);
      }
        console.log(err);
    }
  }

  useEffect(() => {
    if (wallet!=null){
      setLoading(true);
      window.$.ajax({
        url: '/getWalletAssets',
        type: 'GET',
        dataType: "json",
        data: {addr: wallet},
        success: function (data) {
          setItems(sort(order,data));
          setLoading(false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
    }else
      setLoading(true);
  }, [wallet,reload]);

  return (
    <div className="container">
      <div className="btn-group pt-1">
        <Link to={"punks"+(params.get("addr")!=null?'?addr='+params.get("addr"):'')} className={"btn btn-dark"+(pathname.endsWith('wallet') || pathname.endsWith('punks')?' active':'')}>{'Punks'}</Link>
        <Link to={"other"+(params.get("addr")!=null?'?addr='+params.get("addr"):'')} className={"btn btn-dark"+(pathname.endsWith('other')?' active':'')}>{'Other'}</Link>
        <Link to={"onsale"+(params.get("addr")!=null?'?addr='+params.get("addr"):'')} className={"btn btn-dark"+(pathname.endsWith('onsale')?' active':'')}>{'On sale'}</Link>
        <Link to={"offers"+(params.get("addr")!=null?'?addr='+params.get("addr"):'')} className={"btn btn-dark"+(pathname.endsWith('offers')?' active':'')}>{'Offers'}</Link>
        {/* <Link to={"activity"+(params.get("addr")!=null?'?addr='+params.get("addr"):'')} className={"btn btn-dark"+(pathname.endsWith('activity')?' active':'')}>{'Activity'}</Link> */}
      </div>
      {/* {loading && address && <Loading/>} */}
      {!address && !wallet && <div className="container"><div className='alert alert-warning mt-2' role='alert'>Please connect your nami wallet.</div></div>}
      {address && !items.length && !loading && <div className="container"><div className='alert alert-warning mt-2' role='alert'>You don't own any punks, <Link to='/buy'>buy one.</Link></div></div>}
      <Routes>
          <Route path="/punks" element={
            <>
              <Filters totalAssets={items.length} order={order} sort={sortAndSet} reload={() => setReload((val) => {return val+1})} loading={address!=null || wallet!=null?loading:false}/>
              <Grid items={items} sell={sell} borrow={borrow} fight={fight} showButtons={address==wallet}/>
            </>
          }/>

          <Route path='/onsale' element={<GridOnSale address={wallet} lucid={lucid} setTxId={setTxId} setSubmittedModal={setSubmittedModal} showButtons={address==wallet}/>}/>
          <Route path='/other' element={<GridOther  items={items} borrow={borrow}/>}/>
          <Route path='/offers' element={<GridOffers address={address} filterAddress={wallet} offer={offer} lucid={lucid} setTxId={setTxId} setSubmittedModal={setSubmittedModal} showButtons={address==wallet}/>}/>
          <Route path='/activity' element={<Grid items={items} sell={sell} borrow={borrow} fight={fight} showButtons={address==wallet}/>}/>
          <Route path="*" element={
            <>
              <Filters totalAssets={items.filter(i => i.policyId==policyId).length} order={order} sort={sortAndSet} reload={() => setReload((val) => {return val+1})} loading={address!=null || wallet!=null?loading:false}/>
              <Grid items={items} sell={sell} borrow={borrow} fight={fight} showButtons={address==wallet}/>
            </>
          } />
      </Routes>
      
      {sellModal && <ModalSell asset={selected} hide={() => setSellModal(false)} doSell={doSell} enabled={modalEnabled}/>}
      {offerModal && <ModalOffer hide={() => setOfferModal(false)} doOffer={doOffer} enabled={modalEnabled}/>}
      {fightModal && <ModalFight asset={selected} hide={() => setFightModal(false)} doFight={doFight} enabled={modalEnabled}/>}
      {borrowModal && <ModalBorrow asset={selected} hide={() => setBorrowModal(false)} doBorrow={doBorrow} enabled={modalEnabled}/>}
      {submittedModal && <ModalSubmitted asset={selected} hide={() => setSubmittedModal(false)} txId={txId}/>}
    </div>
  );
}

export default Wallet;