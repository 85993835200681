import {Link} from 'react-router-dom';
import {useEffect} from 'react';

function Index() {

  window.scrollTo(0, 0);

  useEffect(() => {
    window.$.ajax({
      url: 'getStats',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        window.$('#totalPunks').html(data.assets.toLocaleString("en-US"));
        window.$('#totalVolume').html('₳ '+data.vol.toLocaleString("en-US"));
        window.$('#totalTrades').html(data.trades.toLocaleString("en-US"));
        window.$('#athSale').html('₳ '+data.ath.toLocaleString("en-US"));
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  }, []);

  return (
    <div className='mt-3'>
      <div className='container'>
        <div  className='container'>
        <div className="row bg-body shadow-sm rounded-3 py-3">
          <div className='col-6 col-sm-3' >
            <h3 id="totalPunks" className="text-center text-body-emphasis">...</h3>
            <div className='text-center'>Total punks</div>
          </div>
          <div className='col-6 col-sm-3'>
            <h3 id="totalVolume" className="text-center text-body-emphasis">...</h3>
            <div className='text-center'>Total volume</div>
          </div>
          <div className='col-6 col-sm-3 mt-4 mt-sm-0'>
            <h3 id="totalTrades" className="text-center text-body-emphasis">...</h3>
            <div className='text-center'>Total trades</div>
          </div>
          <div className='col-6 col-sm-3 mt-4 mt-sm-0'>
            <h3 id="athSale" className="text-center text-body-emphasis">...</h3>
            <div className='text-center'>Largest sale</div>
          </div>
        </div>
        </div>
      </div>


      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row-reverse align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>Welcome to ADAPunks</h1>
            <p className='lead'>The original collection of punks on the Cardano Blockchain!</p>
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/nftOrange.png' className='d-block mx-auto img-fluid  rounded-3' width='300' height='300' alt='ADAPunk orange' loading="eager" crossorigin="anonymous" fetchpriority="high"/>
          </div>
        </div>
      </div>

      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>New loan system</h1>
            <p className='lead'>Borrow your punks to get liquidity or lend ADA to earn more ADA!</p>
            <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
              {/* <Link type='button' to='/fight' class='btn btn-primary btn-lg px-4 me-md-2'>Fight</Link> */}
              <Link type='button' to='/lending/borrows' className='btn btn-primary btn-lg px-4 me-md-2'>Earn ADA</Link>
            </div>
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/nftImg.png' className='d-block mx-auto img-fluid rounded-3' width='300' height='300' alt='ADAPunk ape' loading="eager" crossorigin="anonymous" fetchpriority="high"/>
          </div>
        </div>
      </div>

      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row-reverse align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>Marketplace</h1>
            <p className='lead'>Buy and sell in our own Marketplace with the market's lowest fees</p>
            <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
              {/* <Link type='button' to='/fight' class='btn btn-primary btn-lg px-4 me-md-2'>Fight</Link> */}
              <Link type='button' to='/buy' className='btn btn-primary btn-lg px-4 me-md-2'>Buy now</Link>
            </div>
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/nft.png' className='d-block mx-auto img-fluid rounded-3' width='300' height='300' alt='ADAPunk zombie' loading="eager" crossorigin="anonymous" fetchpriority="high"/>
          </div>
        </div>
      </div>

      {/* <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <h1 className="display-5 fw-bold text-body-emphasis lh-1 text-center mb-5">Team</h1>
        <div className="row  mt-3">
          <div className="col-lg-4 text-center">
            <img className="bd-placeholder-img rounded-circle" width="140" height="140" src='nftImg?id=22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee41444150554e4b37363630' role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
            <h2 className="fw-normal">JAV</h2>
            <p className='text-warning'>Team Lead</p>
          </div>
          <div className="col-lg-4 text-center">
          <img className="bd-placeholder-img rounded-circle" width="140" height="140" src='nftImg?id=22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee41444150554e4b30363338' role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
            <h2 className="fw-normal">Albert</h2>
            <p className='text-warning'>Developer</p>
          </div>
          <div className="col-lg-4 text-center">
          <img className="bd-placeholder-img rounded-circle" width="140" height="140" src='nftImg?id=22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee41444150554e4b32383836' role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
            <h2 className="fw-normal">Poseidón</h2>
            <p className='text-warning'>Team</p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Index;