import {useState,useEffect, useRef} from 'react';
import {Network, colapse,colapseMore, policyId,getStore} from '../shared.js'
import { fromText } from 'lucid-cardano';
import { Link } from 'react-router-dom';

function HistoryTop({address,emptyWallet}) {

  const loading = useRef(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    window.$.ajax({
      url: 'getTopSales',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        loading.current=false;
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  }, []);


  function getStrEvent(e){
    if (e==1)
      return "Transfer"
    else if (e==2)
      return "List"
    if (e==3)
      return "Delist"
    if (e==4)
      return "Sale"
    else
      return "???"
  }

  return (
    <div className="container mt-3">
      <div className="bg-body shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <h3 className="text-body-emphasis">Top 50 sales</h3>
      </div>

      <table className='table rounded-bottom-3 bg-body'>
        <thead>
        <tr>
            <th scope='col'>Asset</th>
            <th className='d-none d-xl-table-cell'scope='col'>Event</th>
            <th scope='col'>Amount</th>
            <th className='d-none d-xl-table-cell' scope='col'>Store</th>
            <th className='d-none d-xl-table-cell' scope='col'>From</th>
            <th className='d-none d-xl-table-cell' scope='col'>To</th>
            <th className='d-none d-xl-table-cell' scope='col'>UTC Date</th>
            <th scope='col'>TxId</th>
        </tr>
        </thead>
        <tbody>
          {loading.current &&
          <>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td className='d-none d-xl-table-cell'scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td className='d-none d-xl-table-cell'scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td className='d-none d-xl-table-cell'scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
          </>
          }
          {!loading.current &&
          <>
            {items.map((item,index) =>
              <tr key={index}>
              <td><div className='d-flex'><Link to={'/punk?id='+policyId+fromText(item.tokenName)}><img className='rounded-2' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.tokenName)}/></Link> <div className='mx-2'><div>{item.tokenName}</div> <div className='d-xl-none'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.from}><label style={{cursor:'pointer'}} className={(address===item.from?"text-warning":"")}>{colapseMore(item.from)}</label></Link> &gt; <Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.to}><label style={{cursor:'pointer'}} className={(address===item.to?"text-warning":"")}>{colapseMore(item.to)}</label></Link></div></div></div></td>
              <td className='d-none d-xl-table-cell'>{getStrEvent(item.event)}</td>
              <td>{(item.event == 4 ? <label className='fw-bolder text-body-emphasis'>{'₳ '+item.amount.toLocaleString("en-US")}</label> : null)} {(getStore(item.sc)==='jpg.store' ? <div className='d-xl-none'><a target='_blank' href={'https://www.jpg.store/asset/'+policyId+fromText(item.tokenName)}>{getStore(item.sc)}</a></div> : <div className='d-xl-none'>{getStore(item.sc)}</div> )}</td>
              {(getStore(item.sc)==='jpg.store' ? <td className='d-none d-xl-table-cell'><a target='_blank' href={'https://www.jpg.store/asset/'+policyId+fromText(item.tokenName)}>{getStore(item.sc)}</a></td> : <td className='d-none d-xl-table-cell'>{getStore(item.sc)}</td> )}
              <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.from}><label style={{cursor:'pointer'}} className={(address===item.from?"text-warning":"")}>{colapse(item.from)}</label></Link></td>
              <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.to}><label style={{cursor:'pointer'}} className={(address===item.to?"text-warning":"")}>{colapse(item.to)}</label></Link></td>
              <td className='d-none d-xl-table-cell'>{item.date.replace(".000Z","").replace("T"," ")}</td>
              <td><div className='d-xl-none'>{item.date.substring(0,10).replace("T"," ")}</div><a href={'https://'+(Network=="Preview"?"preview.":"")+'cardanoscan.io/transaction/'+item.txid+''} target='_blank'>{colapse(item.txid)}</a></td>
            </tr>
            )}
          </>
          }
        </tbody>
      </table>
    </div>
  );
}

export default HistoryTop;