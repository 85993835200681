import Card from '../shared/Card';
import {Link} from 'react-router-dom';

function GridDefault({pkh,items,pay,claim}) {

  return (
    <>
      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-4 row-cols-xxl-5 g-4 mt-2'>
        {
          items.map((item) =>
            <Card
              key={item.id}
              id={item.id} 
              name={item.name}
              rank={item.rank}
              polId={item.policyId}

            >
              {!item.onTx && <div className='fw-bolder mt-3'>₳ {item.amount+' / APR '+item.apy+'% / '+item.days+' D'}</div>}
              <div className='fw-bolder mb-3'>Interest: <label className='text-body-emphasis'>₳ {item.interest.toFixed(2)}</label></div>

              {item.datum.owner===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => pay(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Pay':'Pending Tx...'}</button>}
              {item.datum.lender===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => claim(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Claim':'Pending Tx...'}</button>}
            </Card>
          ) 
        }
      </div>
    </>
  );
}

export default GridDefault;