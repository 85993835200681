import {useState} from 'react';

function ModalFight({asset,hide,doFight,enabled}) {

  const [amount, setAmount] = useState('');
  const [coin, setCoin] = useState('ADAP');
  const [turn1, setTurn1] = useState('');
  const [turn2, setTurn2] = useState('');
  const [turn3, setTurn3] = useState('');

  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Let's start a fight</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>

          <div id='fight-modal-body' className='modal-body'>
          <h1 className='fs-5 pb-1'>Set your bet.</h1>
            <div className='d-flex'>
              <img className='rounded-2' style={{maxWidth: '50px'}} src={'/nftImg?id='+asset.id}/>
              <h4 className='mx-3 mt-2'>Bet </h4>
              <input type='number' value={amount} onChange={e => setAmount(e.target.value)}  min='0' step='1' className='form-control input-lg'/>


              <select id='coin' style={{marginLeft: '10px'}}  value={coin} onChange={e => {setCoin(e.target.value)}} className='form-select form-select-lg' aria-label='.form-select-lg example'>
                <option value='ADAP'>ADAP</option>
                <option value='ADA' selected>ADA</option>
              </select>
            </div>

            <h1 className='fs-5 pt-4 pb-1'>Pick your turns.</h1>
            <div className='row'>
              <div className='col'>
                <select className='form-select form-select-lg' value={turn1} onChange={e => {setTurn1(e.target.value)}} aria-label='.form-select-lg example'>
                  <option value=''></option>
                  <option value='1'>Stone</option>
                  <option value='2'>Paper</option>
                  <option value='3'>Scissors</option>
                </select>
              </div>
              <div className='col'>
                <select className='form-select form-select-lg' value={turn2} onChange={e => {setTurn2(e.target.value)}} aria-label='.form-select-lg example'>
                  <option value=''></option>
                  <option value='1'>Stone</option>
                  <option value='2'>Paper</option>
                  <option value='3'>Scissors</option>
                </select>
              </div>
              <div className='col'>
              <select className='form-select form-select-lg' value={turn3} onChange={e => {setTurn3(e.target.value)}} aria-label='.form-select-lg example'>
                <option value=''></option>
                <option value='1'>Stone</option>
                <option value='2'>Paper</option>
                <option value='3'>Scissors</option>
              </select>
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' disabled={!enabled} onClick={() => doFight(amount,coin,turn1,turn2,turn3)}>Start Fight</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ModalFight;