import {Link} from 'react-router-dom';

function Faq() {


  return (
    <div className='container'>
    <h1 style={{marginTop: '20px'}}>What is adapunks.io</h1>
    <p>Adapunks.io is a decentralized application (dApp) on the Cardano BlockChain to bring utility to the ADAPunks holders.<br/>
    In adapunks.io you can find two main funcions: a Marketplace and a Loan system.</p>

    <h3>Marketplace</h3>
    <p>Buy and sell ADAPunks.</p>

    <h3>Loan system</h3>
    <p>You can borrow your punks and other asssets validated to get liquidity. Or you can fund borrows to earn ADA.<br/>
    Validated collections
    <ul>
      <li>ADAPunks</li>
      <li>The Ape Society</li>
      <li>Tappy by TapTools</li>
      <li>Mutants</li>
    </ul>
    </p>
    <h1 style={{marginTop: '50px'}}>Fees</h1>
    <p>Only 4%, the cheapes on market. Half is for adapunks.io to pay the bills (service) and the other half is for the collection (royalties)</p>

    <h1 style={{marginTop: '50px'}}>Policy ID</h1>
    <p>22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee</p>

    <h1 style={{marginTop: '50px'}}>Collection fees</h1>
    <p>If you are the owner of a collection and want to receive the royalties you must hold 10 ADAPunks and contact us.</p>

    <h1 style={{marginTop: '50px'}}>Who is developing adapunks.io</h1>
    <p>One holder who wants the collection in the CNFT top 10. Lover of developing and Cardano. <a href='mailto:albert@adamail.me'>albert@adamail.me</a></p>
    </div>
  );
}

export default Faq;