import {useState} from 'react';
import {tasaFee} from '../../shared.js'

function ModalEdit({asset,hide,doEdit,enabled}) {

  const [amount, setAmount] = useState(asset.amount);

  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Sell update</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>
          <div className='modal-body'>
            <div className="d-flex px-3 mb-3">
                <img className='rounded-2' style={{maxWidth: '50px'}} src={'/nftImg?id='+asset.id}/>
                <h4 className="mx-3 mt-2">{asset.name}</h4>
              </div>
              <div className='d-flex'>
                  <img  className='rounded-2' style={{maxWidth: '50px'}}/> 
                  <h4 className='mx-3'>Amount ₳ </h4>
                  <input type='number' value={amount} onChange={e => setAmount(e.target.value)} min='0'  step='1'/>
              </div>
              <h5 className='mx-3 mt-3'>You will receive: {amount && (amount*tasaFee)<1?(amount-2):(parseInt(amount-(amount*(tasaFee*2))))} ADA</h5>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' disabled={!enabled} onClick={() => doEdit(amount)}>Update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalEdit;