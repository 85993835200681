import {getAddressDetails,Data} from "lucid-cardano"
import {Lending,UtxoLocker,LendingRef} from '../shared.js'

function Commands({lucid,address}) {



  async function signAndSubmitCardanoTx(tx) {
    try {
        const signedTx = await tx.sign().complete();
        const tid = await signedTx.submit();
        console.log("Cardano tx submitted: " + tid);
        return tid;
    } catch (err) {
      if (err.code===2 && err.info==="Wallet could not send the tx."){
        window.$('#alert-modal-body').html("The asset has been bought/contended by other user. Is not available any more. Or you have a pending transaction.");
        window.$('#alert-modal').modal('show');
        console.log(err);
      }else if (err.code!=2 && err.code!=-3){ //2 User declined to sign the transaction. -3 wallet closed
        window.$('#alert-modal-body').html("You have a pending transaction, please wait a moment an try again.");
        window.$('#alert-modal').modal('show');
        console.log(err);
      }
        console.log(err);
    }
  }

  async function deploy(){
    const pkh = getAddressDetails(address).paymentCredential.hash;
    const UtxoLockerAddress = await lucid.utils.validatorToAddress(UtxoLocker);
    const tx = await lucid
    .newTx()
    .payToAddressWithData(
      UtxoLockerAddress,
        { inline: Data.to(pkh), scriptRef: Lending },
        {}
    )
    .complete();

    console.log(await signAndSubmitCardanoTx(tx));
  }


  async function unlock(ref){
    const pkh = getAddressDetails(address).paymentCredential.hash;
    const utxo = await lucid.utxosByOutRef([ref]);
    const tx = await lucid
    .newTx()
    .collectFrom(utxo, Data.void())
    .attachSpendingValidator(UtxoLocker)
    .addSignerKey(pkh)
    .complete();

    console.log(await signAndSubmitCardanoTx(tx));
  }


  async function getUtxo(ref){
    console.log(await lucid.utxosByOutRef([ref]));
  }


  return (
    <div><button onClick={deploy}>Deploy</button>
    <button onClick={() => getUtxo({txHash:LendingRef,outputIndex:0})}>GetUTxO</button>
    <button onClick={() => unlock({txHash:LendingRef,outputIndex:0})}>Unlock</button>
    </div>
  );
}

export default Commands;