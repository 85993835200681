import Card from '../shared/Card';
import {policyId} from '../../shared.js'

function Grid({items,sell,fight,borrow,showButtons}) {


  return (
    <>
      <div className='row row-cols-2  row-cols-sm-3 row-cols-md-4 row-cols-xl-5 g-4 mt-2'>
        {
          items.filter(i => i.policyId==policyId).map((item) =>
              <Card
                key={item.id}
                id={item.id} 
                name={item.name}
                rank={item.rank}
                polId={item.policyId}
              >
                {showButtons && 
                  <>
                    <button className='btn btn-primary col-12 mt-2' onClick={() => sell(item.id)} type='button'  disabled={item.onTx}>{!item.onTx?'Sell':'Pending Tx...'}</button>
                    <button className='btn btn-primary col-12 mt-2' onClick={() => borrow(item.id)} type='button'  disabled={item.onTx}>{!item.onTx?'Borrow':'Pending Tx...'}</button>
                  </>
                }
              </Card>
          ) 
        }
      </div>
    </>
  );
}

export default Grid;