import {Link} from 'react-router-dom';

function Updates() {


  return (
    <div className='container'>
      <div className="my-3 p-3 bg-body rounded shadow-sm">
        <h3 className="border-bottom pb-2 mb-0 text-body-emphasis">Recent updates</h3>

        <div className="d-flex text-body-secondary pt-3">
          {/* <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#e83e8c"></rect><text x="50%" y="50%" fill="#e83e8c" dy=".3em">32x32</text></svg> */}
          <p className="pb-3 mb-0 small lh-sm border-bottom">
            <strong className="d-block text-gray-dark">Halloween updates! - 2023/01/11</strong>
            <ul>
              <li>You can now borrow "Mutants" assets.</li>
              <li>Loans stats in the loan history section.</li>
              <li>Borrowers address show up.</li>
            </ul>
          </p>
        </div>
        <div className="d-flex text-body-secondary pt-3">
          {/* <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#e83e8c"></rect><text x="50%" y="50%" fill="#e83e8c" dy=".3em">32x32</text></svg> */}
          <p className="pb-3 mb-0 small lh-sm border-bottom">
            <strong className="d-block text-gray-dark">New collections added - 2023/23/10</strong>
              You can now borrow your "Tappy by TapTools" and "The Ape Society" assets.
          </p>
        </div>
        <div className="d-flex text-body-secondary pt-3">
          {/* <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6f42c1"></rect><text x="50%" y="50%" fill="#6f42c1" dy=".3em">32x32</text></svg> */}
          <p className="pb-3 mb-0 small lh-sm border-bottom">
            <strong className="d-block text-gray-dark">Loan system released - 2023/19/10</strong>
              The ADAPunks loan system is available. You can borrow your punks to get liquidity or lend ADA to earn more ADA.
          </p>
        </div>
        {/* <small className="d-block text-end mt-3">
          <a href="#">All updates</a>
        </small> */}
      </div>
    </div>
  );
}

export default Updates;