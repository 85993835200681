import {useState,useEffect, useRef} from 'react';
import {LendingRef,SCRedeemer,feesWallet,royaltiesWallet,MarketPlace, Lending,LendingDatum,policyId} from '../../shared.js'
import {getAddressDetails,Data,fromText} from "lucid-cardano"
import * as DB from '../../db.js';
import Loading from '../shared/Loading.jsx';
import Filters from './Filters.jsx';
import Grid from './GridLoans.jsx';
import ModalSubmitted from '../shared/ModalSubmitted.jsx';
import {Route, Routes, Link, useLocation} from 'react-router-dom';
import GridActives from './GridActives.jsx';
import GridBorrows from './GridBorrows.jsx';
import GridLoans from './GridLoans.jsx';
import GridDefault from './GridDefault.jsx';
import ModalLend from './ModalLend.jsx';
import ModalTakeOffer from './ModalTakeOffer.jsx';



/* global BigInt */

function Lendings({address,lucid,items, setItems,order, setOrder}) {

  const { hash, pathname, search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const [txId, setTxId] = useState('');
  const selected = useRef();
  const [borrowModal, setBorrowModal] = useState(false);
  //To disable the buy/fight/borrow modal button when wallet singning
  const [modalEnabled, setModalEnabled] = useState(true);
  const [submittedModal, setSubmittedModal] = useState(false);
  const [takeOfferModal, setTakeOfferModal] = useState(false);



  const pkh = (address!=null?getAddressDetails(address).paymentCredential.hash:null);


  function sortAndSet(by) {
    setOrder(by);
    setItems(sort(by,items));
  }
  function sort(by,items) {
    const newItems = [...items];
    if (by==="recently")
    newItems.sort(function(a, b) {
      if (a.index < b.index) return 1;
      if (a.index > b.index) return -1;
      return 0;
    });
    if (by==="price")
    newItems.sort(function(a, b) {
      if (a.amount < b.amount) return -1;
      if (a.amount > b.amount) return 1;
      return 0;
    });
    if (by==="rank")
    newItems.sort(function(a, b) {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return 0;
    });

    return newItems;
  }

  function lend(){
    setBorrowModal(true);
    setModalEnabled(true);
  }


  async function doLend(amount,interest,fees,days,apy){
    if (amount===null || amount<10)
      alert("The amount must be at least 10 ADA/ADAP.");
    else{
      setModalEnabled(false);
      const LendingAddress = await lucid.utils.validatorToAddress(Lending);


      const pkh = getAddressDetails(address).paymentCredential.hash;
      const pkhFees = getAddressDetails(feesWallet).paymentCredential.hash;
      const pkhRoyalties = getAddressDetails(royaltiesWallet).paymentCredential.hash;

      var lovelaceFees = (parseInt(fees)*1000000);
  
      const d = {
        owner: '',
        lender: pkh,
        feesWallet: pkhFees,
        royaltiesWallet: pkhRoyalties,
        fees: BigInt(lovelaceFees),
        royalties: BigInt(lovelaceFees),
        amount: BigInt((parseInt(amount)*1000000)-(lovelaceFees*2)),
        interest: BigInt(Math.ceil(interest*1000000)),
        days: BigInt(days*24*60*60*1000),
        deadline: BigInt(0),
        status: BigInt(1),
        policy: policyId,
        tn: ''
      };

      const datum = Data.to(d,LendingDatum);
      var tx = await lucid
      .newTx()
      .payToContract(LendingAddress, { inline: datum }, {lovelace: BigInt((amount)*1000000) })
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      setModalEnabled(true);
      if (txid!=null){
        DB.regBorrow(txid,address,policyId,'',amount,interest,days,apy,royaltiesWallet,feesWallet,1);
        setTxId(txid);
        setBorrowModal(false);
        setSubmittedModal(true);
      }
    }
  }
  
  async function fund(id){
    selected.current=items.find(item => item.id===id);

    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

    const pkh = getAddressDetails(address).paymentCredential.hash;
    const LendingAddress = await lucid.utils.validatorToAddress(Lending);

    let deadline = new Date();
    deadline.setDate(deadline.getDate() + (selected.current.days));

    let fDate = new Date();
    fDate.setHours(fDate.getHours()-1)
    let tDate = new Date();
    tDate.setHours(tDate.getHours()+3)

    // console.log(fDate)
    // console.log(tDate)
    // console.log(deadline)

    const d = {
      owner: selected.current.datum.owner,
      lender: pkh,
      feesWallet: selected.current.datum.feesWallet,
      royaltiesWallet: selected.current.datum.royaltiesWallet,
      fees: BigInt(selected.current.datum.fees),
      royalties: BigInt(selected.current.datum.royalties),
      amount: BigInt(selected.current.datum.amount),
      interest: BigInt(selected.current.datum.interest),
      days: BigInt(selected.current.datum.days),
      deadline: BigInt(deadline),
      status: BigInt(2),
      policy: selected.current.datum.policy,
      tn: selected.current.datum.tn
    };
    const datum = Data.to(d,LendingDatum);

    const utxo = selected.current.utxo;
    const {addr,feesAddr,royAddr} = await DB.getBorrowAddrs(utxo.txHash);

    try{
      var tx = await lucid
      .newTx()
      .payToAddress(addr, { lovelace: BigInt(selected.current.datum.amount) })
      .payToAddress(feesAddr, {lovelace: BigInt(selected.current.datum.fees) })
      .payToAddress(royAddr, {lovelace: BigInt(selected.current.datum.royalties) })
      .collectFrom([utxo],  Data.to(1n))
      // .attachSpendingValidator(Lending)
      .readFrom(await lucid.utxosByOutRef([{txHash:LendingRef,outputIndex:0}]))
      .payToContract(LendingAddress, { inline: datum }, {[selected.current.id]: 1n })
      .validFrom(fDate)
      .validTo(tDate)
      // .validFrom(Date.now())
      // if (window.walletFees){
      //   if (parseInt(selected.current.datum.amount)*0.001 <1000000)
      //     tx = await tx.payToAddress(window.walletFees, { lovelace: 1000000n });
      //   else
      //     tx = await tx.payToAddress(window.walletFees, { lovelace: BigInt(parseInt(selected.current.datum.amount)*0.001) });
      // }
      tx = await tx.complete();
      const newTxId = await signAndSubmitCardanoTx(tx);
      if (newTxId!=null){
        DB.fundBorrow(utxo.txHash,newTxId,address);
        setTxId(newTxId);
        setSubmittedModal(true);
      }else{
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
      }
    }catch(e){
      console.log(e);
      window.$('#alert-modal-body').html("Insufficient founds :(");
      window.$('#alert-modal').modal('show');

        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
    }
  }

  function takeOffer(id){
    selected.current = items.find(item => item.id===id);
    setTakeOfferModal(true);
    setModalEnabled(true);
  }
  async function doTakeOffer(asset){
    if (asset==null){
      window.$('#alert-modal-body').html("You must pick a punk.");
      window.$('#alert-modal').modal('show');
    }else{
      setModalEnabled(false);
      //Disable item
      const newItems = [...items];
      const item = newItems.find(item => item.id===selected.current.id);
      item.onTx=true;
      setItems(newItems);

      const pkh = getAddressDetails(address).paymentCredential.hash;
      const LendingAddress = await lucid.utils.validatorToAddress(Lending);

      let deadline = new Date();
      deadline.setDate(deadline.getDate() + (selected.current.days));

      let fDate = new Date();
      fDate.setHours(fDate.getHours()-1)
      let tDate = new Date();
      tDate.setHours(tDate.getHours()+3)

      // console.log(fDate)
      // console.log(tDate)
      // console.log(deadline)

      const d = {
        owner: pkh,
        lender: selected.current.datum.lender,
        feesWallet: selected.current.datum.feesWallet,
        royaltiesWallet: selected.current.datum.royaltiesWallet,
        fees: BigInt(selected.current.datum.fees),
        royalties: BigInt(selected.current.datum.royalties),
        amount: BigInt(selected.current.datum.amount),
        interest: BigInt(selected.current.datum.interest),
        days: BigInt(selected.current.datum.days),
        deadline: BigInt(deadline),
        status: BigInt(2),
        policy: selected.current.datum.policy,
        tn: fromText(asset.name)
      };
      const datum = Data.to(d,LendingDatum);

      const utxo = selected.current.utxo;
      const {addr,feesAddr,royAddr} = await DB.getBorrowAddrs(utxo.txHash);

      try{
        var tx = await lucid
        .newTx()
        .payToAddress(feesAddr, {lovelace: BigInt(selected.current.datum.fees) })
        .payToAddress(royAddr, {lovelace: BigInt(selected.current.datum.royalties) })
        .collectFrom([utxo],  Data.to(2n))
        // .attachSpendingValidator(Lending)
        .readFrom(await lucid.utxosByOutRef([{txHash:LendingRef,outputIndex:0}]))
        .payToContract(LendingAddress, { inline: datum }, {[asset.id]: 1n })
        .validFrom(fDate)
        .validTo(tDate)
        // .validFrom(Date.now())
        // if (window.walletFees){
        //   if (parseInt(selected.current.datum.amount)*0.001 <1000000)
        //     tx = await tx.payToAddress(window.walletFees, { lovelace: 1000000n });
        //   else
        //     tx = await tx.payToAddress(window.walletFees, { lovelace: BigInt(parseInt(selected.current.datum.amount)*0.001) });
        // }
        tx = await tx.complete();
        const newTxId = await signAndSubmitCardanoTx(tx);
        if (newTxId!=null){
          DB.takeBorrowOffer(utxo.txHash,newTxId,address,asset.name);
          setTxId(newTxId);
          setSubmittedModal(true);
          setTakeOfferModal(false);
        }else{
          //Enable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===selected.current.id);
          item.onTx=false;
          setItems(newItems);
        }
        setModalEnabled(true);
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');

          //Enable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===selected.current.id);
          item.onTx=false;
          setItems(newItems);
          setModalEnabled(true);
      }
    }
  }

  async function pay(id){
    selected.current=items.find(item => item.id===id);

    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

    const utxo = selected.current.utxo;
    const {lenderAddr} = await DB.getBorrowAddrs(utxo.txHash);
    try{
      var tx = await lucid
      .newTx()
      .collectFrom([utxo], Data.to(3n))
      .payToAddress(lenderAddr, { lovelace: BigInt(parseInt(selected.current.datum.amount)+parseInt(selected.current.datum.interest)+parseInt(selected.current.datum.fees)+parseInt(selected.current.datum.royalties)) })
      // .attachSpendingValidator(Lending)
      .readFrom(await lucid.utxosByOutRef([{txHash:LendingRef,outputIndex:0}]))
      .addSignerKey(pkh)
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      if (txid!=null){
        await DB.pay(utxo.txHash,txid,id);
        DB.unBorrow(utxo.txHash);
        setTxId(txid);
        setSubmittedModal(true);
      }else{
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
      }
    }catch(e){
      console.log(e);
      window.$('#alert-modal-body').html("Insufficient founds :(");
      window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
    }
  }

  async function claim(id){
    selected.current=items.find(item => item.id===id);

    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

    const utxo = selected.current.utxo;
    try{
      var tx = await lucid
      .newTx()
      .collectFrom([utxo], Data.to(4n))
      // .attachSpendingValidator(Lending)
      .readFrom(await lucid.utxosByOutRef([{txHash:LendingRef,outputIndex:0}]))
      .addSignerKey(pkh)
      .validFrom(Date.now())
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      if (txid!=null){
        await DB.claim(utxo.txHash,txid,id);
        DB.unBorrow(utxo.txHash);
        setTxId(txid);
        setSubmittedModal(true);
      }else{
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
      }
    }catch(e){
      console.log(e);
      window.$('#alert-modal-body').html("Insufficient founds :(");
      window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
    }
  }


  async function cancel(id){
    selected.current=items.find(item => item.id===id);

    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

    const utxo = selected.current.utxo;
    try{
      var tx = await lucid
      .newTx()
      .collectFrom([utxo], Data.to(0n))
      // .attachSpendingValidator(Lending)
      .readFrom(await lucid.utxosByOutRef([{txHash:LendingRef,outputIndex:0}]))
      .addSignerKey(pkh)
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      if (txid!=null){
        DB.unBorrow(utxo.txHash);
        setTxId(txid);
        setSubmittedModal(true);
      }else{
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
      }
    }catch(e){
      console.log(e);
      window.$('#alert-modal-body').html("Insufficient founds :(");
      window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
    }
  }


  async function signAndSubmitCardanoTx(tx) {
    try {
        const signedTx = await tx.sign().complete();
        const tid = await signedTx.submit();
        console.log("Cardano tx submitted: " + tid);
        return tid;
    } catch (err) {
        if (err.code===2 && err.info==="Wallet could not send the tx."){
          window.$('#alert-modal-body').html("The asset has been bought/contended by other user. Is not available any more. Or you have a pending transaction.");
          window.$('#alert-modal').modal('show');
          console.log(err);
        }else if (err.code!=2 && err.code!=-3){ //2 User declined to sign the transaction. -3 wallet closed
          window.$('#alert-modal-body').html("You have a pending transaction, please wait a moment an try again.");
          window.$('#alert-modal').modal('show');
          console.log(err);
        }
        console.log(err);
    }
}
 
  useEffect(() => {
      setLoading(true);
      window.$.ajax({
        url: '/getBorrows',
        type: 'GET',
        dataType: "json",
        success: function (data) {
          // console.log(data)
          setItems(sort(order,data));
          setLoading(false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
  }, [reload]);

  return (
    <div className="container">
      <div className="btn-group pt-1">
        <Link to="/lending/borrows" className={"btn btn-dark"+(pathname.endsWith('borrows')?' active':'')}>{'Borrows ('+items.filter(e => e.datum.status==0).length+')'}</Link>
        <Link to="/lending/offers" className={"btn btn-dark"+(pathname.endsWith('offers')?' active':'')}>{'Offers ('+items.filter(e => e.datum.status==1).length+')'}</Link>
        <Link to="/lending/active" className={"btn btn-dark"+(pathname.endsWith('active')?' active':'')}>{'Active ('+items.filter(e => e.datum.status==2 && e.datum.deadline>new Date().getTime()).length+')'}</Link>
        <Link to="/lending/default" className={"btn btn-dark"+(pathname.endsWith('default')?' active':'')}>{'Default ('+items.filter(e => e.datum.status==2 && e.datum.deadline<new Date().getTime()).length+')'}</Link>
      </div>
      <Filters totalAssets={items.length} order={order} sort={sortAndSet} reload={() => setReload((val) => {return val+1})} loading={loading} lend={lend}/>

      {/* {loading && <Loading/>} */}
      <Routes>
          <Route path="/borrows" element={<GridBorrows pkh={pkh} items={items.filter(e => e.datum.status==0)} fund={fund} cancel={cancel}/>} />
          <Route path='/offers' element={<GridLoans pkh={pkh} items={items.filter(e => e.datum.status==1)} takeOffer={takeOffer} cancel={cancel}/>}/>
          <Route path='/active' element={<GridActives pkh={pkh} items={items.filter(e => e.datum.status==2 && e.datum.deadline>new Date().getTime())} pay={pay} claim={claim}/>}/>
          <Route path='/default' element={<GridDefault pkh={pkh} items={items.filter(e => e.datum.status==2 && e.datum.deadline<new Date().getTime())} pay={pay} claim={claim}/>}/>
          <Route path="*" element={<GridBorrows pkh={pkh} items={items.filter(e => e.datum.status==0)} fund={fund} cancel={cancel}/>} />
      </Routes>
      {!items.length && !loading && <div className="container"><div className='alert alert-warning mt-2' role='alert'>No borrows found.</div></div>}
      {submittedModal && <ModalSubmitted asset={selected.current} hide={() => setSubmittedModal(false)} txId={txId}/>}
      {borrowModal && <ModalLend hide={() => setBorrowModal(false)} doLend={doLend} enabled={modalEnabled}/>}
      {takeOfferModal && <ModalTakeOffer address={address} asset={selected.current} hide={() => setTakeOfferModal(false)} doTakeOffer={doTakeOffer} enabled={modalEnabled}/>}

    </div>
  );
}

export default Lendings;