import {useState,useEffect,useRef} from 'react';
import {fromText} from "lucid-cardano"

function ModalTakeOffer({address,asset,hide,doTakeOffer,enabled}) {



  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Take offer</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>

          <div id='fight-modal-body' className='modal-body'>
            <div className='d-flex'>
              <h4 className='mx-3 mt-2'> {parseInt(asset.utxo.assets.lovelace/1000000) + ' ₳ for '} </h4>

              <div className="d-flex px-3 mb-3">
                <img className='rounded-2' style={{maxWidth: '50px'}} src={'/nftImg?id='+asset.policyId+asset.assetName}/>
                <h4 className="mx-3 mt-2">{asset.name}</h4>
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' disabled={!enabled} onClick={() => doTakeOffer()}>Take</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ModalTakeOffer;