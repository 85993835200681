import Card from '../shared/Card';
import {Link} from 'react-router-dom';

function GridActives({pkh,items,pay,claim}) {

  function getInfo(deadline){
    const result = {};

    result.days = parseInt((Number(deadline)-new Date().getTime())/1000/60/60/24);
    result.hours = parseInt(((Number(deadline)-new Date().getTime())/1000/60/60) -  (result.days*24));
    result.minutes = parseInt(((Number(deadline)-new Date().getTime())/1000/60) -  (result.days*24*60) -  (result.hours*60));


    return result;
  }


  return (
    <>
      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-4 row-cols-xxl-5 g-4 mt-2'>
        {
          items.map((item) =>
            <Card
              key={item.id}
              id={item.id} 
              name={item.name}
              rank={item.rank}
              polId={item.policyId}
            >
              {!item.onTx && <div className='fw-bolder mt-3'>₳ {item.amount+' / APR '+item.apy+'% / '+item.days+' D'}</div>}
              {!item.onTx && <div className='fw-bolder'>{(getInfo(item.datum.deadline).days>0?getInfo(item.datum.deadline).days + " days ":"") + 
                                        (getInfo(item.datum.deadline).hours + " hours ") + 
                                        (getInfo(item.datum.deadline).days<=0?getInfo(item.datum.deadline).minutes + " mins ":"") + 
                                        ' left'}</div>}
              <div className='fw-bolder mb-3'>Interest: <label className='text-body-emphasis'>₳ {item.interest.toFixed(2)}</label></div>

              {item.datum.owner===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => pay(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Pay':'Pending Tx...'}</button>}
              {/* {item.datum.lender===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => claim(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Claim':'Pending Tx...'}</button>} */}

            </Card>
          ) 
        }
      </div>
    </>
  );
}

export default GridActives;