import {useState,useEffect, useRef} from 'react';
import {Network, colapse, policyId} from '../shared.js'
import { fromText } from 'lucid-cardano';
import { Link } from 'react-router-dom';

function HistoryLocalSales({address,emptyWallet}) {

  const loading = useRef(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    window.$.ajax({
      url: 'getSales',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        loading.current=false;
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  }, []);

  return (
    <div className="container mt-3">
      <div className="bg-body shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <h3 className="text-body-emphasis">Sales history</h3>
      </div>

      <table className='table rounded-bottom-3 bg-body'>
        <thead>
        <tr>
            <th scope='col'>Asset</th>
            <th scope='col'>Amount</th>
            <th className='d-none d-xl-table-cell' scope='col'>Seller</th>
            <th className='d-none d-xl-table-cell' scope='col'>Buyer</th>
            <th className='d-none d-xl-table-cell' scope='col'>UTC Date</th>
            <th scope='col'>TxId</th>
        </tr>
        </thead>
        <tbody>
          {loading.current &&
          <>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
          </>
          }
          {!loading.current &&
          <>
            {items.map((item) =>
              <tr>
                <td><Link to={'/punk?id='+policyId+fromText(item.tokenName)}><img className='rounded-2' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.tokenName)}/></Link> {item.tokenName}</td>
                <td><label className='fw-bolder text-body-emphasis'>{'₳ '+item.amount.toLocaleString("en-US")}</label></td>
                <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.seller}><label style={{cursor:'pointer'}} className={(address===item.seller?"text-warning":"")}>{colapse(item.seller)}</label></Link></td>
                <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.buyer}><label style={{cursor:'pointer'}} className={(address===item.buyer?"text-warning":"")}>{colapse(item.buyer)}</label></Link></td>
                <td className='d-none d-xl-table-cell'>{item.date.replace(".000Z","").replace("T"," ")}</td>
                <td><a href={'https://'+(Network=="Preview"?"preview.":"")+'cardanoscan.io/transaction/'+item.txid+''} target='_blank'>{colapse(item.txid)}</a></td>
              </tr>
            )}
          </>
          }
        </tbody>
      </table>
    </div>
  );
}

export default HistoryLocalSales;