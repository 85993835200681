import {useState,useEffect,useRef} from 'react';
import { useSearchParams } from 'react-router-dom';
import * as DB from '../../db.js';
import Loading from '../shared/Loading.jsx';
import Filters from './Filters.jsx';
import Grid from './Grid.jsx';
import { Link } from 'react-router-dom';


/* global BigInt */

function AllPunks({address,lucid,items, setItems}) {

  const [loading, setLoading] = useState(false);
  const page = useRef(0);
  const [searchParams, setSearchParams] = useSearchParams();


  function doFilter(str){
    if (str!=="")
      setSearchParams({filter: str},{replace:true});
    else
      setSearchParams({},{replace:true});

    page.current=0;
    items.filter=str;
    reload();
  }


  function loadMore(){
    page.current = page.current + 1;
    reload();
  }

  function reload(){
    setLoading(true);
    window.$.ajax({
      url: 'getAllPunks',
      type: 'GET',
      dataType: "json",
      data: {page: page.current, filter: items.filter},
      success: function (data) {
        var newData;
        if (page.current==0)
          newData = [];
        else
          newData = [... items.assets];
        setItems({filter:items.filter,total:data.total,assets:newData.concat(data.assets)});
        setLoading(false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
  }
 
  useEffect(() => {
    if (items.total==0)
      reload();

    //reclick on AllPunks
    if(items.filter!==searchParams.get('filter')){
      items.filter=searchParams.get('filter');
      page.current=0;
      window.$('#AllPunksSearch').val('');
      setItems({filter:null,total:0,assets:[]});
      reload();
    }
  }, [searchParams]);

  return (
    <div className="container">
      <Filters totalAssets={items.total} reload={reload} loading={loading} filter={items.filter} setFilter={doFilter}/>
      {/* {loading && address && <Loading/>} */}
      {!items.total && !loading && <div className="container"><div className='alert alert-warning mt-2' role='alert'>No punks finded.</div></div>}
      {<Grid items={items.assets} loading={loading} loadMore={loadMore} showMore={(items.assets.length!=items.total)}/>}
    </div>
  );
}

export default AllPunks;