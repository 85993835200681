import { Link } from 'react-router-dom';
import './card.css';
import {policyId} from '../../shared.js'


function Card({id, name, rank, children, polId }) {

  // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl))

  return (
    <div className='col'>
      <div className='card'>
        {rank!=null && polId==policyId && 
          <Link to={'/punk?id='+id}>
            <div className='rounded card-img-top'>
              <div className='imgContainer'>
                <img src={'/nftImg2?id='+id} loading='lazy' className='card-img-top'/>
              </div>
            </div>
          </Link>
        }
        {polId!=policyId && 
            <div className='rounded card-img-top'>
              <div className='imgContainer'>
                <img src={'/nftImg2?id='+id} loading='lazy' className='card-img-top'/>
              </div>
            </div>
        }
        {rank==null && polId==policyId && <div className='rounded card-img-top'><div className='imgContainer'><img src='/imgs/anyPunk.png' loading='lazy' className='card-img-top'/></div></div>}
        <div className='card-body'>
          <div className='row'>
            <div className='card-title col'>{name}</div>
            {rank!=null && <div className='col text-end mytooltip'>
              <label className={'rounded-1 px-1 text-dark '+(rank<=10?'bg-danger':(rank<=100?'bg-warning':(rank<1000?'bg-info':'bg-secondary')))}>{rank}</label>
              <span className="mytooltiptext">Punk Rank</span>
            </div>}
          </div>
            {children}
        </div>
      </div>
    </div>
  );
}

export default Card;