import {useState} from 'react';
import { FiRefreshCcw } from "react-icons/fi";


function Filters({totalAssets,order,sort,reload,loading}) {


  return (
    <div className='container'>
      <div className='bg-body shadow-sm mt-3 p-3 rounded-3'>
        <div className='row'>
          <div className='col-12 col-md-3 col-lg-2'>
            <select className='form-select form-select-lg' value={order} onChange={e => {sort(e.target.value)}} aria-label='.form-select-lg example'>
              <option value='rank'>By rank</option>
              <option value='name'>By name</option>
            </select>
          </div>
          <div className='col-6 col-md-1 offset-md-5  offset-lg-7  text-center pt-2' >
            {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
            {!loading && <FiRefreshCcw style={{cursor:'pointer',width: '25px',height: '25px'}} onClick={() => {reload()}}/>}
          </div>
          <div className='col-6 col-md-3 col-lg-2 text-center pt-2' >You own: <label>{totalAssets} punks</label></div>
        </div>
      </div>
    </div>
  );
}

export default Filters;