import Card from '../shared/Card';

function Grid({pkh,items,buy,cancel, edit}) {

  return (
    <div className='row row-cols-2  row-cols-sm-3 row-cols-md-4 row-cols-xl-5 g-4 mt-2'>
      {
        items.map((item) =>
          <Card
            key={item.id}
            id={item.id} 
            name={item.name}
            rank={item.rank}
            polId={item.policyId}

          >
            <p className='fw-bolder text-body-emphasis'>₳ {item.amount}</p>
            {item.datum.owner!==pkh && <button className='btn btn-primary col-12 mt-2' onClick={() => buy(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Buy':'Pending Tx...'}</button>}
            <div className='row'>
              {/* <div className='px-1 col-12 col-lg-6 mt-2'> */}
              <div className='px-1 col mt-2'>
                {item.datum.owner===pkh && <button className='btn btn-warning w-100' onClick={() => cancel(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Delist':'On Tx...'}</button>}
              </div>
              <div className='px-1 col-12 col-lg-6 mt-2'>
                {item.datum.owner===pkh && <button className='btn btn-warning w-100' onClick={() => edit(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Edit':'On Tx...'}</button>}
              </div>
            </div>
          </Card>
        ) 
      }
    </div>
  );
}

export default Grid;