import {colapse,Network} from '../../shared.js'

function ModalSubmitted({asset,hide,txId}) {


  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Transaction successfully submitted for: </h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>
          <div className='modal-body'>
              { asset!=null && asset.name!=null &&
                <div className="d-flex px-3 mb-3">
                  <img className='rounded-2' style={{maxWidth: '50px'}} src={'/nftImg?id='+asset.id}/>
                  <h4 className="mx-3 mt-2">{asset.name}</h4>
                </div>
              }
              <h5 className='mx-3 mt-3'>TxId: <a target='_blank' href={'https://'+(Network=='Preview'?'preview.':'')+'cardanoscan.io/transaction/'+txId}>{colapse(txId)}</a></h5>
              <h6 className='mx-3 mt-3'>The transaction may need a moment to take effect, the time depends on the cardano network load.</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalSubmitted;