import * as DB from '../../db.js';
import {SCScript,SCRedeemer,feesWallet,royaltiesWallet,MarketPlace,policyId,Offers,OffersDatum,MarketPlaceDatum,tasaFee} from '../../shared.js'
import {Data,getAddressDetails,fromText} from "lucid-cardano"


/* global BigInt */

export async function cancelSale(id,pkh,lucid,items,setItems){
    const selected=items.find(item => item.id===id);

    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

    if (selected.version==1){
      const r = {
        option: BigInt(0),
        hash1: "",
        hash2: "",
      };
      const redeemer = Data.to(r, SCRedeemer);
      const utxo = selected.utxo;
      try{
        var tx = await lucid
        .newTx()
        .collectFrom([utxo], redeemer)
        .attachSpendingValidator(SCScript)
        .addSignerKey(pkh)
        .complete();
        const txid = await signAndSubmitCardanoTx(tx);
        if (txid!=null){
          DB.delist(utxo.txHash);
          return txid;
        }else{
          //Enable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===id);
          item.onTx=false;
          setItems(newItems);
          return null;
        }
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
        return null;
      }
    //MarketPlace V2
    }else{
      const utxo = selected.utxo;
      try{
        var tx = await lucid
        .newTx()
        .collectFrom([utxo], Data.to(0n))
        .attachSpendingValidator(MarketPlace)
        .addSignerKey(pkh)
        .complete();
        const txid = await signAndSubmitCardanoTx(tx);
        if (txid!=null){
          DB.delist(utxo.txHash);
          return txid;
        }else{
          //Enable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===id);
          item.onTx=false;
          setItems(newItems);
          return null;
        }
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
        return null;
      }
    }
  }


  export async function editSale(selected,pkh,address,lucid,total){
    const MarketPlaceAddress = await lucid.utils.validatorToAddress(MarketPlace);

    if (selected.version==1){
      window.$('#alert-modal-body').html("You can't edit this asset. Please delist an list again.");
      window.$('#alert-modal').modal('show');
      return null;
    //MarketPlace V2
    }else{
      if (total===null || total<10){
        window.$('#alert-modal-body').html("The import must be at least 10 ADA.");
        window.$('#alert-modal').modal('show');
      }else{
        const utxo = selected.utxo;
        const pkhFees = getAddressDetails(feesWallet).paymentCredential.hash;
        const pkhRoyalties = getAddressDetails(royaltiesWallet).paymentCredential.hash;
        var fees = (total*1000000)*tasaFee;
        if (fees<1000000)
          fees=1000000;
        const amount = (total*1000000) - (fees*2);
  
        const d = {
          owner: pkh,
          feesWallet: pkhFees,
          royaltiesWallet: pkhRoyalties,
          fees: BigInt(fees),
          royalties: BigInt(fees),
          policy: "",
          tn: "",
          amount: BigInt(amount)
        };
        const datum = Data.to(d,MarketPlaceDatum);
        try{
          var tx = await lucid
          .newTx()
          .collectFrom([utxo], Data.to(0n))
          .attachSpendingValidator(MarketPlace)
          .payToContract(MarketPlaceAddress, { inline: datum }, {[selected.id]: 1n })
          .addSignerKey(pkh)
          .complete();
          const txid = await signAndSubmitCardanoTx(tx);
          if (txid!=null){
            DB.delist(utxo.txHash);
            DB.regList(selected.policyId,selected.name,amount,txid,address,feesWallet,royaltiesWallet);
            return txid;
          }else{
            return null;
          }
        }catch(e){
          console.log(e);
          window.$('#alert-modal-body').html("Insufficient founds :(");
          window.$('#alert-modal').modal('show');
          return null;
        }
      }
    }
  }

  export async function doOfferTx(total,address,lucid,setModalEnabled,tokenName='ANY'){
    const OffersAddress = await lucid.utils.validatorToAddress(Offers);
    if (total===null || total<10){
      window.$('#alert-modal-body').html("The import must be at least 10 ADA.");
      window.$('#alert-modal').modal('show');
    }else{
      setModalEnabled(false);
      const pkh = getAddressDetails(address).paymentCredential.hash;
      const pkhFees = getAddressDetails(feesWallet).paymentCredential.hash;
      const pkhRoyalties = getAddressDetails(royaltiesWallet).paymentCredential.hash;

      var fees = (total*1000000)*tasaFee;
      if (fees<1000000)
        fees=1000000;
      const amount = (total*1000000);

      const d = {
        owner: pkh,
        feesWallet: pkhFees,
        royaltiesWallet: pkhRoyalties,
        fees: BigInt(fees),
        royalties: BigInt(fees),
        policy: policyId,
        tn: fromText(tokenName),
        amount: BigInt(1)
      };
      const datum = Data.to(d,OffersDatum);
      var tx = await lucid
      .newTx()
      .payToContract(OffersAddress, { inline: datum }, {lovelace: BigInt(amount) })
      .complete();
      const txid = await signAndSubmitCardanoTx(tx);
      setModalEnabled(true);
      if (txid!=null){
          DB.regList(policyId,'ANY',total,txid,address,feesWallet,royaltiesWallet);
      }

      return txid;
    }
  }


  export async function buyTx(item,address,lucid){
    //MarketPlace V1
    if (item.version==1){
      const lovelaceAmount = item.amount*1000000;
      const utxo = item.utxo;
      const fee = BigInt(Math.round(parseInt(lovelaceAmount)*tasaFee));
      const pay = BigInt(parseInt(lovelaceAmount))-(fee*2n);
      const {addr} = await DB.getListAddrs(utxo.txHash);
      
      const r = {
          option: BigInt(2),
          hash1: "",
          hash2: "",
      };
      const redeemer = Data.to(r, SCRedeemer);

      try{
        var tx = await lucid
        .newTx()
        .payToAddress(addr, { lovelace: pay })
        .payToAddress(feesWallet, {lovelace: fee })
        .payToAddress(royaltiesWallet, {lovelace: fee })
        .collectFrom([utxo], redeemer)
        .attachSpendingValidator(SCScript)
        if (window.walletFees){
          if (parseInt(pay)*0.001 <1000000)
            tx = await tx.payToAddress(window.walletFees, { lovelace: 1000000n });
          else
            tx = await tx.payToAddress(window.walletFees, { lovelace: BigInt(parseInt(pay)*0.001) });
        }
        tx = await tx.complete();
        const txid = await signAndSubmitCardanoTx(tx);
        if (txid!=null){
          DB.regSale(item.policyId,item.name,parseInt(item.amount),txid,address,addr);
          DB.delist(utxo.txHash);
          return txid;
        }else{
          return null;
        }
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');
      }
    //MarketPlace V2
    }else{
      const utxo = item.utxo;
      const {addr,feesAddr,royAddr} = await DB.getListAddrs(utxo.txHash);

      try{
        var tx = await lucid
        .newTx()
        .payToAddress(addr, { lovelace: BigInt(item.datum.amount) })
        .payToAddress(feesAddr, {lovelace: BigInt(item.datum.fees) })
        .payToAddress(royAddr, {lovelace: BigInt(item.datum.royalties) })
        .collectFrom([utxo],  Data.to(1n))
        .attachSpendingValidator(MarketPlace)
        if (window.walletFees){
          if (parseInt(item.datum.amount)*0.001 <1000000)
            tx = await tx.payToAddress(window.walletFees, { lovelace: 1000000n });
          else
            tx = await tx.payToAddress(window.walletFees, { lovelace: BigInt(parseInt(item.datum.amount)*0.001) });
        }
        tx = await tx.complete();
        const txid = await signAndSubmitCardanoTx(tx);
        if (txid!=null){
          DB.regSale(item.policyId,item.name,parseInt(item.amount),txid,address,addr);
          DB.delist(utxo.txHash);
          return txid;
        }else{
          return null;
        }
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');
        return null;
      }
    }
  }

  export async function signAndSubmitCardanoTx(tx) {
    try {
        const signedTx = await tx.sign().complete();
        const tid = await signedTx.submit();
        console.log("Cardano tx submitted: " + tid);
        return tid;
    } catch (err) {
        if (err.code===2 && err.info==="Wallet could not send the tx."){
          window.$('#alert-modal-body').html("The asset is not available any more. Or you have a pending transaction.");
          window.$('#alert-modal').modal('show');
          console.log(err);
        }else if (err.code!=2 && err.code!=-3){ //2 User declined to sign the transaction. -3 wallet closed
          window.$('#alert-modal-body').html("You have a pending transaction, please wait a moment and try again.");
          window.$('#alert-modal').modal('show');
          console.log(err);
        }
        console.log(err);
    }
}