import {useState,useEffect, useRef} from 'react';
import {Network, colapse, policyId} from '../shared.js'
import { fromText } from 'lucid-cardano';

function HistoryFights({address}) {

  const loading = useRef(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    window.$.ajax({
      url: 'getFights',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        loading.current=false;
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  }, []);

  return (
<div className="container mt-3">
      <div className="bg-body shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <h3 className="text-body-emphasis">Fights history</h3>
      </div>

      <table className='table rounded-bottom-3 bg-body'>
        <thead>
        <tr>
            <th scope='col'>Fighter</th>
            <th className='d-none d-xl-table-cell' scope='col'>Turns</th>
            <th scope='col'>Contender</th>
            <th className='d-none d-xl-table-cell' scope='col'>Turns</th>
            <th scope='col'>Winner</th>
            <th className='d-none d-xl-table-cell' scope='col'>TxId</th>
        </tr>
        </thead>
        <tbody>
          {loading.current &&
          <>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
          </>
          }
          {!loading.current &&
          <>
            {items.map((item) =>
              <tr>
                <td className={(address===item.faddr?"text-warning":"")}><img className='rounded-2' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.fTokenName)}/> {item.fTokenName}</td>
                <td className='d-none d-xl-table-cell'>
                  <img style={{maxHeight: '35px'}} src={'imgs/' + item.ft1 + '.png'} />
                  <img style={{maxHeight: '35px'}} src={'imgs/' + item.ft2 + '.png'} /> 
                  <img style={{maxHeight: '35px'}} src={'imgs/' + item.ft3 + '.png'} /> 
                </td>
                <td className={(address===item.caddr?"text-warning":"")}><img className='rounded-2' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.cTokenName)}/> {item.cTokenName}</td>
                <td className='d-none d-xl-table-cell'>
                  <img style={{maxHeight: '35px'}} src={'imgs/' + item.ct1 + '.png'} />
                  <img style={{maxHeight: '35px'}} src={'imgs/' + item.ct2 + '.png'} /> 
                  <img style={{maxHeight: '35px'}} src={'imgs/' + item.ct3 + '.png'} /> 
                </td>
                <td className={(address===item.waddr?"text-warning":"")}><img className='rounded-2' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.wTokenName)}/> {item.amount+" "+item.coin}</td>
                <td className='d-none d-xl-table-cell'><a href={'https://'+(Network=="Preview"?"preview.":"")+'cardanoscan.io/transaction/'+item.txid+''} target='_blank'>{colapse(item.txid)}</a></td>
              </tr>
            )}
          </>
          }
        </tbody>
      </table>
    </div>
  );
}

export default HistoryFights;