import {useState,useEffect, useRef, useCallback} from 'react';
import { useSearchParams } from 'react-router-dom';
import {Network, colapse,colapseMore, policyId,getStore,loanSystemAddress} from '../shared.js'
import { fromText } from 'lucid-cardano';
import { Link } from 'react-router-dom';
import Loading from './shared/Loading.jsx';

function HistoryAll({address,emptyWallet,number,data,setData,showSearch}) {

  const [firstLoading, setFirstLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        const newData = {...data};
        newData.page=newData.page+1;
        reload(newData);
      }
    })
    if (node) observer.current.observe(node)
  }, [loading]);


  function doFilter(str){
    if (str!=="")
      setSearchParams({filter: str},{replace:true});
    else
      setSearchParams({},{replace:true});

    setFirstLoading(true);
    const newData = {...data};
    newData.filter=str;
    newData.assets=[];
    newData.page=0;
    reload(newData);
  }


  function reload(tmpNewdata=data){
    setLoading(true);
    window.$.ajax({
      url: 'getAllTxs',
      type: 'GET',
      dataType: "json",
      data: {number: number,page:tmpNewdata.page,filter: tmpNewdata.filter, all:searchParams.get('all')},
      success: function (d) {
        setFirstLoading(false);
        if (d.length<50)
          setHasMore(false);
        else
          setHasMore(true);
        var newData = {...tmpNewdata}; // we dont know if tmpNewdata is data or newData
        newData.assets=newData.assets.concat(d);
        setData(newData);
        setLoading(false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
  }


  useEffect(() => {
    if (data.assets.length==0){
      if (data.filter===searchParams.get('filter')){
        setFirstLoading(true);
        reload();
      }
    } else {
      setFirstLoading(false);
    }

    if(data.filter!==searchParams.get('filter')){
      setFirstLoading(true);
      const newData = {...data};
      newData.filter=searchParams.get('filter');
      newData.assets=[];
      newData.page=0;
      reload(newData);
    } else {
      setFirstLoading(false);
    }
  }, []);

  function getStrEvent(e,sc){
    if (sc==loanSystemAddress)
      return 'Loan';

      
    if (e==1)
      return "Transfer"
    else if (e==2)
      return "List"
    if (e==3)
      return "Delist"
    if (e==4)
      return "Sale"
    else
      return "???"
  }

  return (
    <div className="container mt-3">
      <div className="row bg-body shadow-sm rounded-top-3 py-3">
        <div className='col-12 col-md-4 col-lg-3' >
            <h3 className="text-body-emphasis">Sales history</h3>
        </div>
        {showSearch &&
        <div className='col-12 col-md-8 col-lg-9 d-inline-flex '>
              <label className='pt-1' style={{marginRight:'10px'}}>Search: </label><input onKeyDown={(e) => (e.keyCode == 13 ? doFilter(e.target.value) : null)} id='AllSalesSearch' className='form-control input w-100' defaultValue={data.filter}/>
        </div>
        }
      </div>

      <div className="row">
        <table className='table rounded-bottom-3 bg-body col'>
          <thead>
          <tr>
              <th scope='col'>Asset</th>
              <th className='d-none d-xl-table-cell' scope='col'>Event</th>
              <th scope='col'>Amount</th>
              <th className='d-none d-xl-table-cell' scope='col'>Store</th>
              <th className='d-none d-xl-table-cell' scope='col'>From</th>
              <th className='d-none d-xl-table-cell' scope='col'>To</th>
              <th className='d-none d-xl-table-cell' scope='col'>UTC Date</th>
              <th scope='col'>TxId</th>
          </tr>
          </thead>
          <tbody>
            {firstLoading &&
            <>
              <tr>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
              </tr>
              <tr>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
              </tr>
              <tr>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                  <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
              </tr>
            </>
            }
            {!firstLoading &&
            <>
              {data.assets.map((item, index) =>
                <tr key={index} ref={data.assets.length === index + 1 ? lastElementRef : null}>
                  <td><div className='d-flex'><Link to={'/punk?id='+policyId+fromText(item.tokenName)}><img className='rounded-2' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.tokenName)}/></Link> <div className='mx-2'><div>{item.tokenName}</div> <div className='d-xl-none'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.from}><label style={{cursor:'pointer'}} className={(address===item.from?"text-warning":"")}>{colapseMore(item.from)}</label></Link> &gt; <Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.to}><label style={{cursor:'pointer'}} className={(address===item.to?"text-warning":"")}>{colapseMore(item.to)}</label></Link></div></div></div></td>
                  <td className='d-none d-xl-table-cell'>{getStrEvent(item.event,item.sc)}</td>
                  <td>{(item.event == 4 ||  (item.event == 2 && item.amount>25)? <label className='fw-bolder text-body-emphasis'>{'₳ '+item.amount.toLocaleString("en-US")}</label> : <div className='d-xl-none'>{getStrEvent(item.event)}</div>)} {(getStore(item.sc)==='jpg.store' ? <div className='d-xl-none'><a target='_blank' href={'https://www.jpg.store/asset/'+policyId+fromText(item.tokenName)}>{getStore(item.sc)}</a></div> : <div className='d-xl-none'>{getStore(item.sc)}</div> )}</td>
                  {(getStore(item.sc)==='jpg.store' ? <td className='d-none d-xl-table-cell'><a target='_blank' href={'https://www.jpg.store/asset/'+policyId+fromText(item.tokenName)}>{getStore(item.sc)}</a></td> : <td className='d-none d-xl-table-cell'>{getStore(item.sc)}</td> )}
                  <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.from}><label style={{cursor:'pointer'}} className={(address===item.from?"text-warning":"")}>{colapse(item.from)}</label></Link></td>
                  <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.to}><label style={{cursor:'pointer'}} className={(address===item.to?"text-warning":"")}>{colapse(item.to)}</label></Link></td>
                  <td className='d-none d-xl-table-cell'>{item.date.replace(".000Z","").replace("T"," ")}</td>
                  <td><div className='d-xl-none'>{item.date.substring(0,10).replace("T"," ")}</div><a href={'https://'+(Network=="Preview"?"preview.":"")+'cardanoscan.io/transaction/'+item.txid+''} target='_blank'>{colapse(item.txid)}</a></td>
                </tr>
              )}
            </>
            }
          </tbody>
        </table>
      </div>
      {loading && address && <Loading/>}
    </div>
  );
}

export default HistoryAll;