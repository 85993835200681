import {useState,useEffect, useRef} from 'react';
import {Network, colapse, policyId} from '../shared.js'
import { fromText } from 'lucid-cardano';
import { Link } from 'react-router-dom';

function HistoryLoans({address,emptyWallet}) {

  const loading = useRef(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    window.$.ajax({
      url: 'getAllLoans',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        loading.current=false;
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
    window.$.ajax({
      url: 'getLoanStats',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        window.$('#volume').html('₳ '+data.vol.toLocaleString("en-US"));
        window.$('#interest').html('₳ '+data.interest.toLocaleString("en-US"));
        window.$('#active').html(data.active.toLocaleString("en-US"));
        window.$('#total').html(data.total.toLocaleString("en-US"));
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    })
  }, []);

  return (
    <div className="container mt-3">
        <div  className='container my-3'>
          <div className="row bg-body shadow-sm py-3  rounded-3">
            <div className='col-6 col-sm-3' >
              <h3 id="volume" className="text-center text-body-emphasis">...</h3>
              <div className='text-center'>Total loaned</div>
            </div>
            <div className='col-6 col-sm-3'>
              <h3 id="interest" className="text-center text-body-emphasis">...</h3>
              <div className='text-center'>Interest earned</div>
            </div>
            <div className='col-6 col-sm-3 mt-4 mt-sm-0'>
              <h3 id="active" className="text-center text-body-emphasis">...</h3>
              <div className='text-center'>Active loans</div>
            </div>
            <div className='col-6 col-sm-3 mt-4 mt-sm-0'>
              <h3 id="total" className="text-center text-body-emphasis">...</h3>
              <div className='text-center'>Total loans</div>
            </div>
          </div>
        </div>

      <div className="bg-body shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <h3 className="text-body-emphasis">Loans history</h3>
      </div>
      <table className='table rounded-bottom-3 bg-body'>
        <thead>
        <tr>
            <th scope='col'>Asset</th>
            <th scope='col'>Amount</th>
            <th scope='col'>Interest</th>
            <th className='d-none d-xl-table-cell' scope='col'>Borrower</th>
            <th className='d-none d-xl-table-cell' scope='col'>Lender</th>
            <th className='d-none d-xl-table-cell' scope='col'>UTC Date</th>
            <th scope='col'>Status</th>
            <th className='d-none d-xl-table-cell' scope='col'>TxId</th>
        </tr>
        </thead>
        <tbody>
          {loading.current &&
          <>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td className='d-none d-xl-table-cell' scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-5'></span></div></td>
            </tr>
          </>
          }
          {!loading.current &&
          <>
            {items.map((item) =>
              <tr>
                <td>
                  {item.policyId==policyId && <Link to={'/punk?id='+policyId+fromText(item.tokenName)}><img className='rounded-2 me-1' style={{maxWidth: '35px'}} src={'nftImg?id='+policyId+fromText(item.tokenName)}/></Link>}
                  {item.policyId!=policyId && <img className='rounded-2 me-1' style={{maxWidth: '35px'}} src={'nftImg?id='+(item.tokenId?item.tokenId:item.policyId+fromText(item.tokenName))}/>}
                  {item.tokenName}</td>
                <td><label className='fw-bolder text-body-emphasis'>{'₳ '+item.amount.toLocaleString("en-US")}</label></td>
                <td><label className='fw-bolder text-body-emphasis'>{'₳ '+item.interest.toLocaleString("en-US")}</label></td>
                <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.borrower}><label style={{cursor:'pointer'}} className={(address===item.borrower?"text-warning":"")}>{colapse(item.borrower)}</label></Link></td>
                <td className='d-none d-xl-table-cell'><Link onClick={emptyWallet} to={'/wallet/punks?addr='+item.lender}><label style={{cursor:'pointer'}} className={(address===item.lender?"text-warning":"")}>{colapse(item.lender)}</label></Link></td>
                <td className='d-none d-xl-table-cell'>{item.date.replace(".000Z","").replace("T"," ")}</td>
                <td><label className='fw-bolder text-body-emphasis'>{(item.status==3?'Paid':'Defaulted')}</label></td>
                <td className='d-none d-xl-table-cell' ><a href={'https://'+(Network=="Preview"?"preview.":"")+'cardanoscan.io/transaction/'+item.txid+''} target='_blank'>{colapse(item.txid)}</a></td>
              </tr>
            )}
          </>
          }
        </tbody>
      </table>
    </div>
  );
}

export default HistoryLoans;