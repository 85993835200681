import Card from '../shared/Card';
import {Link} from 'react-router-dom';
import {colapse} from '../../shared.js'

function GridBorrows({pkh,items,fund,cancel}) {

  return (
    <>
      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-4 row-cols-xxl-5 g-4 mt-2'>
        {
          items.map((item) =>
            <Card
              key={item.id}
              id={item.id} 
              name={item.name}
              rank={item.rank}
              polId={item.policyId}

            >
              {!item.onTx && <div className='fw-bolder mt-3'>₳ {item.amount+' / APR '+item.apy+'% / '+item.days+' D'}</div>}
              <div className='fw-bolder'>Interest: <label className='text-body-emphasis'>₳ {item.interest.toFixed(2)}</label></div>
              <div className='fw-bolder mb-3'><Link to={'/wallet/punks?addr='+item.borrower}>{colapse(item.borrower)}</Link></div>


              {item.datum.owner!==pkh && <button className='btn btn-primary col-12 mt-2' onClick={() => fund(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Fund':'Pending Tx...'}</button>}
              {item.datum.owner===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => cancel(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Delist':'Pending Tx...'}</button>}
            </Card>
          ) 
        }
      </div>
    </>
  );
}

export default GridBorrows;