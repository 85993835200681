import {useRef, useState} from 'react';


function ModalLend({hide,doLend,enabled}) {

  const [amount, setAmount] = useState('');
  const [days, setDays] = useState('7');
  const [apy, setApy] = useState('80');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }
  const interest = useRef(0);
  const fees = useRef(0);

  function calc(amount,days,apy){
    const rate = ((parseInt(apy)/365)*parseInt(days))/100;
    interest.current = parseFloat((parseInt(amount)*rate).toFixed(2));
    fees.current = interest.current * 0.02;
    if (fees.current<1)
      fees.current=1;
    interest.current=interest.current-(fees.current*2)
    if (interest.current<3)
      interest.current=3;

    setDesc1('Interest: '+(interest.current).toFixed(2)+' ADA');
    setDesc2('You\'ll receive: '+(parseInt(amount)+interest.current).toFixed(2)+' ADA');
    var deadline =new Date(new Date().addDays(parseInt(days)));
    
    setDesc3('Before: '+deadline);
  }

  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Loan offer</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>

          <div id='fight-modal-body' className='modal-body'>
          <h1 className='fs-5 pb-1'>Set your loan offer terms.</h1>
            <div className='d-flex'>
              <h4 className='mx-3 mt-2'>₳</h4>
              <input type='number' value={amount} onChange={e => {setAmount(e.target.value);calc(e.target.value,days,apy);}}  min='0' step='1' className='form-control input-lg'/>
              <select id='days' style={{marginLeft: '10px'}}  value={days} onChange={e => {setDays(e.target.value);calc(amount,e.target.value,apy);}} className='form-select form-select-lg' aria-label='.form-select-lg example'>
                {/* <option value='1'>1 day</option> */}
                <option value='7'>7 days</option>
                <option value='15' selected>15 days</option>
                <option value='30' selected>30 days</option>
              </select>
              <select id='apy' style={{marginLeft: '10px'}}  value={apy} onChange={e => {setApy(e.target.value);calc(amount,days,e.target.value);}} className='form-select form-select-lg' aria-label='.form-select-lg example'>
                <option value='50'>50% APR</option>
                <option value='80'>80% APR</option>
                <option value='100' selected>100% APR</option>
                <option value='150'>150% APR</option>
                <option value='200'>200% APR</option>
                <option value='250'>250% APR</option>
              </select>
            </div>
            <h5 className='mx-3 mt-5'>{desc1}</h5>
            <h5 className='mx-3'>{desc2}</h5>
            <h5 className='mx-3'>{desc3}</h5>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' disabled={!enabled} onClick={() => doLend(amount,interest.current,fees.current,days,apy)}>Lend</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ModalLend;