import {useState,useEffect,useRef} from 'react';

function ModalTakeGlobalOffer({address,asset,hide,doTakeOffer,enabled}) {

  const loading = useRef(false);
  const [items, setItems] = useState([]);
  const [payAsset, setPayAsset] = useState(null);


  useEffect(() => {
    if (address!=null){
      loading.current=true;
      window.$.ajax({
        url: 'getWalletAssets',
        type: 'GET',
        dataType: "json",
        data: {addr: address},
        success: function (data) {
          loading.current=false;
          setItems(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
    }
  }, []);

  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Take offer</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>

          <div id='fight-modal-body' className='modal-body'>
            <div className='d-flex'>
              <h4 className='mx-3 mt-2'> {parseInt(asset.utxo.assets.lovelace/1000000) + ' ₳ for '} </h4>
              <div className='rounded-3' style={{width:'50px', height: '50px', backgroundColor: 'lightgray'}}><img className='rounded-2' style={{maxWidth: '50px'}} src={payAsset==null?'':'/nftImg?id='+payAsset.id}/></div>


              <div className="dropdown" style={{marginLeft: '15px', marginTop: '5px'}}>
                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" disabled={loading.current} aria-expanded="false">
                  {loading.current?'Loading...':'Select Punk'}
                </button>
                <ul className="dropdown-menu">
                  {items.map((item) =>
                    <li key={item.id}><a className='dropdown-item' onClick={e => setPayAsset(item)} href='javascript:void(0)'><img style={{maxWidth: '50px'}} src={'nftImg?id='+item.id}/>{item.name}</a></li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' disabled={!enabled} onClick={() => doTakeOffer(payAsset)}>Take</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ModalTakeGlobalOffer;