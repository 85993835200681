import { Link } from 'react-router-dom';
import './card.css';
import {policyId} from '../../shared.js'


function Card2({id, name, rank, children, polId }) {

  // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl))

  var width='108px';

  if (window.screen.width < 1400)
    width='111.6px';
  if (window.screen.width < 1200)
    width='117px';
  if (window.screen.width < 992)
    width='116px';
  if (window.screen.width < 768)
    width='129px';
  if (window.screen.width < 576)
    width=((window.screen.width-24)/4)+'px';

    
  return (
      <div className='' style={{width:width}}>
          <Link to={'/punk?id='+id}>
            <div className='rounded'>
              <div className='imgContainerBlue'>
                <img src={'/nftImg2?id='+id} loading='lazy' className='img-fluid'/>
              </div>
            </div>
          </Link>
      </div>
  );
}

export default Card2;