import {useState,useEffect, useRef} from 'react';
import {SCScript,SCRedeemer,feesWallet,royaltiesWallet,MarketPlace} from '../../shared.js'
import {getAddressDetails,Data} from "lucid-cardano"
import * as DB from '../../db.js';
import {cancelSale,buyTx,editSale} from '../shared/Functions.jsx';
import Loading from '../shared/Loading.jsx';
import Filters from './Filters.jsx';
import Grid from './Grid.jsx';
import ModalSubmitted from '../shared/ModalSubmitted.jsx';
import {usePageTracking} from '../usePageTracking.js'
import ModalEdit from './ModalEdit.jsx';


/* global BigInt */

function Buy({address,lucid,items, setItems,order, setOrder}) {

  usePageTracking();

  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(true);
  const [reload, setReload] = useState(0);
  const [txId, setTxId] = useState('');
  const selected = useRef();
  //To disable the buy/fight modal button when wallet singning
  const [submittedModal, setSubmittedModal] = useState(false);



  const pkh = (address!=null?getAddressDetails(address).paymentCredential.hash:null);


  function sortAndSet(by) {
    setOrder(by);
    setItems(sort(by,items));
  }
  function sort(by,items) {
    const newItems = [...items];
    if (by==="recently")
    newItems.sort(function(a, b) {
      if (a.index < b.index) return 1;
      if (a.index > b.index) return -1;
      return 0;
    });
    if (by==="price")
    newItems.sort(function(a, b) {
      if (a.amount < b.amount) return -1;
      if (a.amount > b.amount) return 1;
      return 0;
    });
    if (by==="rank")
    newItems.sort(function(a, b) {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return 0;
    });

    return newItems;
  }


  function edit(id){
    selected.current=items.find(item => item.id===id);
    setEditModal(true);
    setModalEnabled(true);
  }

  async function doEdit(total){
    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===selected.current.id);
    item.onTx=true;
    setItems(newItems);

    const txid = await editSale(selected.current,pkh,address,lucid,total);
    if (txid!=null){
      setEditModal(false);
      setTxId(txid);
      setSubmittedModal(true);
    }else{
      //Enable item
      const newItems = [...items];
      const item = newItems.find(item => item.id===selected.current.id);
      item.onTx=false;
      setItems(newItems);
    }

  }

  async function buy(id){
    selected.current=items.find(item => item.id===id);

    //Disable item
    var newItems = [...items];
    var item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

    const txid = await buyTx(selected.current,address,lucid);

    if (txid!=null){
      setTxId(txid);
      setSubmittedModal(true);
    }else{
      //Enable item
      newItems = [...items];
      item = newItems.find(item => item.id===id);
      item.onTx=false;
      setItems(newItems);
    }
  }

  async function cancel(id){
    selected.current=items.find(item => item.id===id);
    const txid= await cancelSale(id,pkh,lucid,items,setItems);
    if (txid!=null){
      setTxId(txid);
      setSubmittedModal(true);
    }
  }

 
  useEffect(() => {
      setLoading(true);
      window.$.ajax({
        url: 'getSCLists',
        type: 'GET',
        dataType: "json",
        success: function (data) {
          setItems(sort(order,data));
          setLoading(false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      })
  }, [reload]);

  return (
    <div className="container">
      <Filters totalAssets={items.length} order={order} sort={sortAndSet} reload={() => setReload((val) => {return val+1})} loading={loading}/>
      {/* {loading && <Loading/>} */}
      {!items.length && !loading && <div className="container"><div className='alert alert-warning mt-2' role='alert'>Sorry there are no punks listed. All of them are in diamond hands :)</div></div>}
      {<Grid pkh={pkh} items={items} buy={buy} edit={edit} cancel={cancel}/>}
      {editModal && <ModalEdit asset={selected.current} hide={() => setEditModal(false)} doEdit={doEdit} enabled={modalEnabled}/>}
      {submittedModal && <ModalSubmitted asset={selected.current} hide={() => setSubmittedModal(false)} txId={txId}/>}
    </div>
  );
}

export default Buy;