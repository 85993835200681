import {Link} from 'react-router-dom';

function Team() {


  return (
    <div className="container marketing mt-5">
      <h1 className="display-5 fw-bold text-body-emphasis lh-1 text-center mb-5">ADAPunks Team</h1>
      <div className="row  mt-3">
        <div className="col-lg-4 text-center">
          <img className="bd-placeholder-img rounded-circle" width="140" height="140" src='nftImg?id=22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee41444150554e4b37363630' role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
          <h2 className="fw-normal">JAV</h2>
          <p className='text-warning'>Team Lead</p>
          {/* <p><a className="btn btn-secondary" href="#">View details »</a></p> */}
        </div>
        <div className="col-lg-4 text-center">
        <img className="bd-placeholder-img rounded-circle" width="140" height="140" src='nftImg?id=22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee41444150554e4b37303738' role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
          <h2 className="fw-normal">Albert</h2>
          <p className='text-warning'>Developer</p>
          {/* <p><a className="btn btn-secondary" href="#">View details »</a></p> */}
        </div>
        <div className="col-lg-4 text-center">
        <img className="bd-placeholder-img rounded-circle" width="140" height="140" src='nftImg?id=22b38c9d58710cdddf0205731dc67c916f6737e5e8f99a90bd9213ee41444150554e4b32383836' role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"></img>
          <h2 className="fw-normal">Poseidón</h2>
          <p className='text-warning'>Team</p>
          {/* <p><a className="btn btn-secondary" href="#">View details »</a></p> */}
        </div>
      </div>
    </div>
  );
}

export default Team;