import {useState,useEffect, useRef} from 'react';
import {SCScript,SCRedeemer,feesWallet,royaltiesWallet,Offers,OffersDatum,policyId} from '../../shared.js'
import {getAddressDetails,Data,fromText} from "lucid-cardano"
import * as DB from '../../db.js';
import {doOfferTx} from '../shared/Functions.jsx';
import Loading from '../shared/Loading.jsx';
import ModalSubmitted from '../shared/ModalSubmitted.jsx';
import GridOffers from '../wallet/GridOffers.jsx';
import ModalOffer from '../shared/ModalOffer.jsx';


/* global BigInt */

function OffersView({address,lucid,items, setItems,order, setOrder}) {

  const [offerModal, setOfferModal] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(true);

  const [txId, setTxId] = useState('');
  const selected = useRef();
  //To disable the buy/fight modal button when wallet singning
  const [submittedModal, setSubmittedModal] = useState(false);



  function offer(){
    setOfferModal(true);
    setModalEnabled(true);
  }


  async function doOffer(total){
    const txid= await doOfferTx(total,address,lucid,setModalEnabled);
    if (txid!=null){
      setTxId(txid);
      setOfferModal(false);
      setSubmittedModal(true);
    }
  }

  return (
    <div className="container">
      {/* {loading && <Loading/>} */}
      {<GridOffers address={address} offer={offer} lucid={lucid} setTxId={setTxId} setSubmittedModal={setSubmittedModal} showButtons={true}/>}
      {submittedModal && <ModalSubmitted asset={selected.current} hide={() => setSubmittedModal(false)} txId={txId}/>}
      {offerModal && <ModalOffer hide={() => setOfferModal(false)} doOffer={doOffer} enabled={modalEnabled}/>}

    </div>
  );
}

export default OffersView;