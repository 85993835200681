import {useState} from 'react';
import { FiRefreshCcw } from "react-icons/fi";


function Filters({totalAssets,reload,loading,filter,setFilter}) {

  const [localFilter, setLocalFilter] = useState((filter==null?'':filter));
  const [localOnsale, setLocalOnsale] = useState("all");

  return (
    <div className='container'>
      <div className='bg-body shadow-sm mt-3 p-3 rounded-3'>
        <div className='row'>
          <div className='col-7 col-md-5 col-lg-7 d-inline-flex '>
            <label className='pt-1' style={{marginRight:'10px'}}>Search: </label><input id='AllPunksSearch' onChange={(e) => setLocalFilter(e.target.value)} onKeyDown={(e) => (e.keyCode == 13 ? setFilter(localFilter) : null)} className='form-control input w-100' value={localFilter}/>
          </div>
          <div className='col-5 col-md-3 col-lg-2'>
            <select className='form-select form-select-lg' disabled="true" value={localOnsale} onChange={e => {setLocalOnsale(e.target.value)}} aria-label='.form-select-lg example'>
              <option value='all'>All</option>
              <option value='onSale'>On sale</option>
              <option value='adapunksio'>adapunks.io</option>
              <option value='jpgstore'>jpg.store</option>
            </select>
          </div>
          <div className='col-6 col-md-1 text-center pt-2' >
            {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
            {!loading && <FiRefreshCcw style={{cursor:'pointer',width: '25px',height: '25px'}} onClick={() => {setFilter(localFilter)}}/>}
          </div>
          <div className='col-6 col-md-3 col-lg-2 text-center pt-2' >Finded <label>{totalAssets} punks</label></div>
        </div>
      </div>
    </div>
  );
}

export default Filters;