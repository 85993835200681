import {useState,useEffect} from 'react'
import Card from '../shared/Card';
import {signAndSubmitCardanoTx} from '../shared/Functions.jsx';
import { FiRefreshCcw } from "react-icons/fi";
import {Offers,SCScript,SCRedeemer,MarketPlace} from '../../shared.js'
import {getAddressDetails,Data, fromText} from "lucid-cardano"
import * as DB from '../../db.js';
import ModalTakeGlobalOffer from '../offers/ModalTakeGlobalOffer';
import ModalTakePunkOffer from '../offers/ModalTakePunkOffer';

/* global BigInt */

function GridOffers({address,filterAddress,offer,lucid,showButtons,setTxId,setSubmittedModal}) {

  const [modalEnabled, setModalEnabled] = useState(true);
  const [selected, setSelected] = useState(null);
  const [takingGlobalModal, setTakingGlobalModal] = useState(false);
  const [takingPunkModal, setTakingPunkModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState('recently');
  const [reload, setReload] = useState(0);
  const [items, setItems] = useState([]);
  const pkh = (address!=null?getAddressDetails(address).paymentCredential.hash:null);

  async function cancel(id){
    const selected = items.find(item => item.id===id);
    setSelected(selected);


    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===id);
    item.onTx=true;
    setItems(newItems);

      const utxo = selected.utxo;
      try{
        var tx = await lucid
        .newTx()
        .collectFrom([utxo], Data.to(0n))
        .attachSpendingValidator(Offers)
        .addSignerKey(pkh)
        .complete();
        const txid = await signAndSubmitCardanoTx(tx);
        if (txid!=null){
          DB.delist(utxo.txHash);
          setTxId(txid);
          setSubmittedModal(true);
          return txid;
        }else{
          //Enable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===id);
          item.onTx=false;
          setItems(newItems);
          return null;
        }
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===id);
        item.onTx=false;
        setItems(newItems);
        return null;
      }
  }

  function takeOffer(id){
    const selected = items.find(item => item.id===id);
    setSelected(selected);
    if(selected.name=='ANY')
      setTakingGlobalModal(true);
    else
      setTakingPunkModal(true);
    setModalEnabled(true);
  }

  async function doTakeOffer(payAsset){
    if (payAsset==null){
      window.$('#alert-modal-body').html("You must pick a punk.");
      window.$('#alert-modal').modal('show');
    }else{
      //Disable item
      const newItems = [...items];
      const item = newItems.find(item => item.id===selected.id);
      item.onTx=true;
      setItems(newItems);

      const utxo = selected.utxo;
      const {addr,feesAddr,royAddr} = await DB.getListAddrs(utxo.txHash);

      try{
        setModalEnabled(false);
        var tx = await lucid
        .newTx()
        .collectFrom([utxo], Data.to(1n))
        .payToAddress(addr, { [payAsset.id]: 1n })
        .payToAddress(feesAddr, {lovelace: BigInt(selected.datum.fees)})
        .payToAddress(royAddr, {lovelace: BigInt(selected.datum.royalties)})
        .attachSpendingValidator(Offers)
        .addSignerKey(pkh)
        .complete();
        const txid = await signAndSubmitCardanoTx(tx);
        if (txid!=null){
          DB.regSale(payAsset.policyId,payAsset.name,parseInt(selected.utxo.assets.lovelace/1000000),txid,address,addr);
          DB.delist(utxo.txHash);
          setTakingGlobalModal(false);
          setTxId(txid);
          setSubmittedModal(true);
        }else{
          //Enable item
          const newItems = [...items];
          const item = newItems.find(item => item.id===selected.id);
          item.onTx=false;
          setItems(newItems);
        }
        setModalEnabled(true);
      }catch(e){
        console.log(e);
        window.$('#alert-modal-body').html("Insufficient founds :(");
        window.$('#alert-modal').modal('show');
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===selected.id);
        item.onTx=false;
        setItems(newItems);
        setModalEnabled(true);
      }
    }
  }

  async function doTakePunkOffer(){
    setModalEnabled(false);

    const punkUtxo = await lucid.utxoByUnit(selected.policyId+selected.assetName);
    const MarketPlaceAddress = await lucid.utils.validatorToAddress(MarketPlace);
    const OldMarketPlaceAddress = await lucid.utils.validatorToAddress(SCScript);


    if (punkUtxo.address===address){
      console.log('Punk on wallet')
    }else if (punkUtxo.address===OldMarketPlaceAddress){
      console.log('Punk on sale in Old SC')
    }else if (punkUtxo.address===MarketPlaceAddress){
      console.log('Punk on sale in New SC')
    }else{
      window.$('#alert-modal-body').html("You don't own this punk.");
      window.$('#alert-modal').modal('show');
      setModalEnabled(true);
      return;
    }


    //Disable item
    const newItems = [...items];
    const item = newItems.find(item => item.id===selected.id);
    item.onTx=true;
    setItems(newItems);

    const utxo = selected.utxo;
    const {addr,feesAddr,royAddr} = await DB.getListAddrs(utxo.txHash);

    try{
      var tx = await lucid
      .newTx()
      .collectFrom([utxo], Data.to(1n))
      .payToAddress(addr, { [selected.policyId+selected.assetName]: 1n })
      .payToAddress(feesAddr, {lovelace: BigInt(selected.datum.fees)})
      .payToAddress(royAddr, {lovelace: BigInt(selected.datum.royalties)})
      .attachSpendingValidator(Offers)
      .addSignerKey(pkh);

      if (punkUtxo.address===address){
        //Nothing to do
      }else if (punkUtxo.address===OldMarketPlaceAddress){
        const r = {
          option: BigInt(0),
          hash1: "",
          hash2: "",
        };
        tx=await tx.collectFrom([punkUtxo], Data.to(r, SCRedeemer))
        .attachSpendingValidator(SCScript);
      }else if (punkUtxo.address===MarketPlaceAddress){
        tx=await tx.collectFrom([punkUtxo], Data.to(0n))
        .attachSpendingValidator(MarketPlace);
      }else{
        window.$('#alert-modal-body').html("You don't own this punk.");
        window.$('#alert-modal').modal('show');
        return;
      }

      tx = await tx.complete();
      const txid = await signAndSubmitCardanoTx(tx);
      if (txid!=null){
        DB.regSale(selected.policyId,selected.name,parseInt(selected.utxo.assets.lovelace)/1000000,txid,addr,address);
        DB.delist(utxo.txHash);
        DB.delist(punkUtxo.txHash);
        setTakingPunkModal(false);
        setTxId(txid);
        setSubmittedModal(true);
      }else{
        //Enable item
        const newItems = [...items];
        const item = newItems.find(item => item.id===selected.id);
        item.onTx=false;
        setItems(newItems);
      }
      setModalEnabled(true);
    }catch(e){
      console.log(e);
      window.$('#alert-modal-body').html("Insufficient founds :(");
      window.$('#alert-modal').modal('show');
      //Enable item
      const newItems = [...items];
      const item = newItems.find(item => item.id===selected.id);
      item.onTx=false;
      setItems(newItems);
      setModalEnabled(true);
    }

  }

  function sortAndSet(by) {
    setOrder(by);
    setItems(sort(by,items));
  }

  function sort(by,items) {
    const newItems = [...items];
    if (by==="recently")
    newItems.sort(function(a, b) {
      if (a.index < b.index) return 1;
      if (a.index > b.index) return -1;
      return 0;
    });
    if (by==="price")
    newItems.sort(function(a, b) {
      if (a.utxo.assets.lovelace < b.utxo.assets.lovelace) return 1;
      if (a.utxo.assets.lovelace > b.utxo.assets.lovelace) return -1;
      return 0;
    });
    if (by==="rank")
    newItems.sort(function(a, b) {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return 0;
    });
    return newItems;
  }

  useEffect(() => {
      setLoading(true);
      window.$.ajax({
        url: '/getOffers',
        type: 'GET',
        dataType: "json",
        data: {addr: filterAddress},
        success: function (data) {
          setItems(sort(order,data));
          setLoading(false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
  }, [filterAddress,reload]);


  return (
    <>
      <div className='container'>
        <div className='bg-body shadow-sm mt-3 p-3 rounded-3'>
          <div className='row'>
            <div className='col-7 col-md-3 col-lg-2'>
              <select className='form-select form-select-lg' value={order} onChange={e => {sortAndSet(e.target.value)}} aria-label='.form-select-lg example'>
              <option value='price'>By price</option>
              <option value='recently'>Recently listed</option>
              <option value='rank'>By rank</option>
              </select>
            </div>
            {(filterAddress==null || filterAddress===address) && <div className='col-5 col-md-3 col-lg-2'><button className="btn btn-primary h-100 w-100" onClick={() => offer()}>Add offer</button></div>}
            <div className='col-6 col-md-1 offset-md-2  offset-lg-5  text-center pt-2' >
              {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
              {!loading && <FiRefreshCcw style={{cursor:'pointer',width: '25px',height: '25px'}} onClick={() => setReload((val) => {return val+1})}/>}
            </div>
            <div className='col-6 col-md-3 col-lg-2 text-center pt-2' >Total offers: <label>{items.length} </label></div>
          </div>
        </div>
      </div>
      
      <div className='row row-cols-2  row-cols-sm-3 row-cols-md-4 row-cols-xl-5 g-4 mt-2'>
        {
          items.map((item) =>
            <Card
              key={item.id}
              id={(item.name=='ANY'?item.id:item.policyId+fromText(item.name))} 
              name={(item.name=='ANY'?'Collection offer':item.name)}
              rank={item.rank}
              polId={item.policyId}

            >
              <p className='fw-bolder text-body-emphasis'>₳ {parseInt(item.utxo.assets.lovelace/1000000)}</p>
              {showButtons && 
                <>
                  {item.datum.owner===pkh && <button className='btn btn-warning col-12 mt-2' onClick={() => cancel(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Delist':'Pending Tx...'}</button>}
                  {item.datum.owner!==pkh && <button className='btn btn-primary col-12 mt-2' onClick={() => takeOffer(item.id)} type='button' disabled={item.onTx}>{!item.onTx?'Take':'Pending Tx...'}</button>}

                </>
              }
            </Card>
          ) 
        }
      </div>
      {takingGlobalModal && <ModalTakeGlobalOffer address={address} asset={selected} hide={() => setTakingGlobalModal(false)} doTakeOffer={doTakeOffer} enabled={modalEnabled}/>}
      {takingPunkModal && <ModalTakePunkOffer address={address} asset={selected} hide={() => setTakingPunkModal(false)} doTakeOffer={doTakePunkOffer} enabled={modalEnabled}/>}

    </>
  );
}

export default GridOffers;